.ant-calendar-header {
  a {
    &:hover {
      cursor: pointer;
    }
  }
}

.ant-calendar-prev-year-btn,
.ant-calendar-month-panel-prev-year-btn,
.ant-calendar-year-panel-prev-decade-btn,
.ant-calendar-decade-panel-prev-century-btn {
  &:after {
    content: '‹‹' !important;
  }
}

.ant-calendar-prev-month-btn {
  &:after {
    content: '‹' !important;
  }
}

.ant-calendar-next-month-btn {
  &:after {
    content: '›' !important;
  }
}

.ant-calendar-next-year-btn,
.ant-calendar-month-panel-next-year-btn,
.ant-calendar-year-panel-next-decade-btn,
.ant-calendar-decade-panel-next-century-btn {
  &:after {
    content: '››' !important;
  }
}

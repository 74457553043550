main.ant-layout-content {
  section.ant-layout.ant-layout-has-sider.site-layout-background {
    height: 100%;
  }
}

.ant-layout-sider {
  .logo {
    text-align: center;
    padding-top: 11px;
    padding-bottom: 10px;

    Button {
      margin-top: 15px;
    }
  }

  .ant-menu-submenu-title {
    span {
      i {
        vertical-align: inherit;
        position: relative;
        top: -1px;
      }
    }

    .ant-menu-submenu-arrow {
      transform: translateY(2px);
    }
  }

  .ant-menu-submenu-open {
    .ant-menu-submenu-title {
      .ant-menu-submenu-arrow {
        transform: translateY(0);
      }
    }
  }

  .ant-menu-item {
    margin-top: 0;
    margin-bottom: 0;

    &:first-child {
      margin-top: 8px;
    }

    &:last-child {
      margin-bottom: 8px;
    }

    a,
    a:visited {
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.ant-layout-sider-children {
  overflow: auto;
  padding-bottom: 50px;
}

.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0;
  margin-top: 0;
}

.ant-layout-sider .ant-menu-item:last-child {
  margin-bottom: 0;
}

span.anticon {
  vertical-align: 0.15em;
}

.omot-search-input-sidebar {
  .search-input {
    padding-top: 30px;
    margin-right: 22px;
  }
}

.search-input {
  .form-content {
    display: flex;
    width: 182px;
    margin: 22px auto;

    #searchInput {
      padding: 0.9rem;
      height: 24px;
      border-radius: 10px 0 0 10px;
      color: #4dc1be;
      width: 176px;
      border: 1px solid #4dc1be;
      border-right: none;
      outline: none;
    }

    .search-button {
      padding: 0.9rem;
      width: 22px;
      height: 24px;
      background: $wht;
      border-radius: 0 10px 10px 0;
      border: 1px solid #4dc1be;
      border-left: none;
      position: relative;

      img {
        position: absolute;
        top: 7px;
        right: 8px;
      }
    }
  }
}

.search-input-lg {
  .form-content-lg {
    margin: 22px auto;
    display: flex;

    #searchInput-lg {
      padding: 0.9rem;
      height: 38px;
      background: $wht;
      border-radius: 0 8px 8px 0;
      color: #939393;
      width: 430px;
      border: 1px solid #dadada;
      border-left: none;
      outline: none;
    }

    .search-button-lg {
      padding: 0.9rem;
      height: 38px;
      background: $wht;
      border-radius: 8px 0 0 8px;
      border: 1px solid #dadada;
      border-right: none;
      position: relative;

      img {
        position: absolute;
        top: 7px;
        left: 8px;
        width: 16px;
        height: 16px;
      }
    }
  }
}

.sidebar-narrow,
.sidebar-narrow-active,
.sidebar-narrow-admin-active,
.sidebar-narrow-admin {
  position: fixed;
  width: 105px;
  transition: width 300ms ease-out;
  height: calc(100% - 80px);
  left: 0;
  top: 78px;
  bottom: 0;
  z-index: 100;
  overflow-y: auto;
  background-color: #fbfbfb;
  color: #939393;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90vh;
  overflow-x: hidden;
  &:hover {
    width: 305px;
    transition: width 300ms ease-out;
    span {
      display: block;
    }
  }
  ul {
    li {
      position: relative;
      // &::before {
      //   content: '';
      //   width: 5px;
      //   height: 30px;
      //   background: #35b7b9;

      //   border-radius: 0 6px 6px 0;
      //   position: absolute;
      //   left: -10px;
      //   top: 5px;
      // }
      margin: 20px 10px 10px 10px;
      cursor: pointer;

      p {
        color: #939393;
        height: 38px;
        line-height: 33px;
        display: flex;
        &:hover {
          filter: invert(62%) sepia(93%) saturate(330%) hue-rotate(132deg) brightness(85%) contrast(85%);
        }
      }

      img {
        width: 33px;
        color: #939393;
        margin-left: 30px;
        padding-right: 8px;
        @include transition(all 0.2s linear);

        &:hover {
          filter: invert(62%) sepia(93%) saturate(330%) hue-rotate(132deg) brightness(85%) contrast(85%);
        }
      }

      a {
        text-decoration: none;
        @include transition(font-weight 0.2s linear);

        &:hover {
          font-weight: 700;
        }
      }
      @media (min-width: 1200px) {
        span {
          display: none;
        }
      }

      .sidebar-link {
        &.current {
          font-weight: 700;
        }
      }
    }
    .active-li-element::before {
      content: '';
      width: 6px;
      height: 38px;
      background: #35b7b9;

      border-radius: 0 6px 6px 0;
      position: absolute;
      left: -10px;
      // top: 5px;
    }
  }

  @media (max-width: 1080px) {
    width: 200px;

    ul {
      li {
        img {
          margin-left: 10px;
        }
      }
    }
  }
}

.sidebar-narrow-admin,
.sidebar-narrow-admin-active {
  width: 120px;
}

//NavMenu Sidebar
.ant-layout-sider {
  &.ant-layout-sider-dark {
    margin-top: 50px;
    content: '';
    width: 5px;
    // height: 25px;
    background: #fff;
    border-radius: 0 6px 6px 0;
    position: absolute;
    left: 66px;
    @media (max-width: 1200px) {
      left: -45px;
    }
    @media (max-width: 992px) {
      left: 0;
    }

    top: 20px;

    @media (min-width: 992px) {
      margin-left: 40px !important;
    }

    &.ant-layout-sider-collapsed {
      &.nav-menu-collapsed {
        margin-left: 0;
      }
    }
  }
}

@media (max-width: 1200px) {
  .sidebar-narrow-admin-active,
  .sidebar-narrow-active {
    display: block;
    width: 270px;
  }
}

@media (max-width: 1200px) {
  .sidebar-narrow-admin,
  .sidebar-narrow {
    display: none;
  }
}

.sidebar-calendar {
  .ant-radio-button-wrapper {
    display: none;
  }

  .ant-picker-calendar-mini .ant-picker-content th {
    padding: 10px;
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background: #627b90;
    border-radius: 50%;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: none;
  }

  .ant-select,
  .ant-select-single.ant-select-open .ant-select-selection-item {
    color: #627b90;
    font-weight: 600;
  }

  .ant-picker-calendar-mini .ant-picker-calendar-header {
    padding-left: 0;
  }

  .ant-picker-calendar-header {
    flex-direction: row-reverse;
    justify-content: start;
  }
}

.left-spacing-calendar {
  margin-left: 210px;
}

@media only screen and (max-width: 992px) {
  .left-spacing-calendar {
    margin-left: 0;
  }
  .ant-layout-sider {
    .logo {
      border: none;
      padding: 20px 0;
      padding-left: 3px;
      padding-right: 3px;
    }
  }

  main.ant-layout-content section.ant-layout.ant-layout-has-sider.site-layout-background {
    display: flex;
    flex-direction: column;
  }

  .ant-layout.ant-layout-has-sider > .ant-layout-content {
    width: 100%;
    padding: 0px !important;
  }
}

/***** TYPE *****/
.text-primary {
	color: #2574ab;
  }
  .text-success {
  color: #4F66A0;
  }
  a.text-success:hover,
  a.text-success:focus {
	color: #1c7781;
  }
  .text-info {
	color: #5bc0de;
  }
  a.text-info:hover,
  a.text-info:focus {
	color: #31b0d5;
  }
  .text-warning {
	color: #e6ad5c;
  }
  a.text-warning:hover,
  a.text-warning:focus {
	color: #df9730;
  }
  .text-danger {
	color: #d9534f;
  }
  a.text-danger:hover,
  a.text-danger:focus {
	color: #c9302c;
  }
  .well {
	border: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
  }
  .close {
	font-weight: 500;
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }
.patients-table,
.doctors-table {
  padding: 25px 8px 8px 80px;
  margin-top: 22px;

  @media (max-width: 1200px) {
    padding: 25px 8px 8px 15px;
  }

  .above-table-content {
    display: flex;
    justify-content: space-between;

    .action-button {
      align-self: center;
      margin-bottom: 22px;
      height: 38px;
      border-radius: 10px;

      span {
        font-size: 16px;
      }
    }

    .search-input {
      max-width: 316px;
      width: 100%;
      margin-bottom: 22px;

      .form-content {
        width: 100%;
        margin: 0 auto;

        ::placeholder {
          color: #627b90;
          opacity: 1;
        }

        :-ms-input-placeholder {
          color: #627b90;
        }

        ::-ms-input-placeholder {
          color: #627b90;
        }

        .table-search {
          height: 38px !important;
          width: 100% !important;
          font-weight: 500;
          font-size: 16px !important;
        }
      }
    }

    .search-button {
      height: 38px;

      img {
        position: absolute;
        top: 9px;
        right: 10px;
        width: 17px;
      }
    }
  }

  .ant-table-thead > tr > th {
    background-color: #c2dde0;
    color: #4dc1be;
    font-family: 'Gotham Book', sans-serif !important;
    font-size: 15px;
    font-weight: 500;
  }

  tr > td {
    color: #627b90;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Gotham Book', sans-serif !important;
  }

  .ant-table-column-title,
  .ant-table-cell {
    text-transform: none;
  }

  .anticon svg {
    color: #4dc1be;
  }

  .ant-input {
    border: 2px solid #4dc1be !important;
    border-radius: 20px !important;
  }
}

.ant-table-wrapper {
  overflow-x: auto !important;
  margin-bottom: 42px;
}

.patients-container,
.doctors-container {
  margin: 0 auto;

  .letters {
    display: flex;
    flex-wrap: wrap;

    h1 {
      color: #1890ff;
      font-size: 2rem;
    }

    .patients {
      display: flex;
      flex-direction: column;
      margin: 4rem;

      .li {
        background: #ff7373;
      }
    }
  }
}

.patients-container {
  padding-top: 47px;

  &.top-space {
    padding-top: 47px;

    @media (max-width: 559px) {
      padding-top: 94px;
    }
  }

  &.no-space {
    padding-top: 0;
  }
}

.count {
  opacity: 0.9;
  color: #1890ff;
  text-align: center;
  text-transform: uppercase;
}

.patients {
  .action-button {
    margin-left: 40px;
  }
}

.patient-modal {
  .ant-modal-body {
    padding: 0 0;
  }

  .ant-card-head {
    border-radius: 13px 13px 0 0;
    display: flex;
    align-items: center;
  }

  .ant-card-body {
    margin-top: 40px;
  }

  .ant-card-head-title {
    margin-left: 10px;
  }
}

.doctor-modal {
  &.patient {
    .ant-card-head {
      .ant-card-head-title {
        margin-left: 0px;
        margin-top: -10px !important;
      }
    }

    .ant-input {
      pointer-events: none;
    }
  }
}

.ant-table-thead tr th,
.anticon-search svg {
  color: $wht !important;
}

@media (max-width: 1250px) {
  .patients-table,
  .doctors-table {
    .ant-table-thead > tr > th {
      font-size: 15px !important;
    }
  }
}

@media (max-width: 768px) {
  .patients-table,
  .doctors-table {
    .above-table-content {
      .search-input {
        max-width: 200px;
      }
    }

    .ant-table-thead > tr > th {
      font-size: 14px !important;
    }

    tr > td {
      font-size: 14px !important;
    }
  }
}

@media (max-width: 420px) {
  .patients-table,
  .doctors-table {
    .above-table-content {
      .search-input {
        max-width: 170px;
      }
    }
  }
}

@media (max-width: 380px) {
  .patients-table,
  .doctors-table {
    .above-table-content {
      .search-input {
        max-width: 140px;
      }
    }
  }
}

th.ant-table-cell {
  padding: 8px 10px !important;
}

.ant-table-cell {
  padding: 4px 10px !important;
}

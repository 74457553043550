.admin_header {
  padding-left: 15px;
  padding-right: 10px;
  margin: 0;
  display: flex;
  align-items: center;
  line-height: 15px !important;

  @media (max-width: 500px) {
    line-height: 0px !important;
  }

  > .anticon {
    color: #fff;
    font-size: 30px;
    outline: none;
    margin-right: 20px;

    @include media('<=960px') {
      font-size: 23px;
    }
  }

  .ant-dropdown-link {
    font-size: 16px;
    color: #fff;
    margin-left: 2rem;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }

    @include media('<=960px') {
      font-size: 12px;
    }

    i {
      vertical-align: baseline;
      margin-left: 6px;
    }
  }

  .ant-select-arrow {
    top: 38%;
  }

  .ant-select-single {
    &:not(.ant-select-customize-input) {
      .ant-select-selector {
        border: none;
      }
    }
  }
}

.ant-dropdown-trigger > .anticon.anticon-down,
.ant-dropdown-link > .anticon.anticon-down,
.ant-dropdown-button > .anticon.anticon-down {
  font-size: 10px;
  vertical-align: baseline;
  margin-right: 100px;
  margin-left: 5px;
}

.ant-layout-header span {
  margin-top: 0 !important;
}

.ant-select-item-option-selected {
  &:not(.ant-select-item-option-disabled) {
    background-color: #ffffff;
    font-weight: 400;

    &:hover {
      background-color: #f5f5f5;
    }
  }
}

.logo-desktop {
  display: flex;

  .logo {
    margin: 6px 0 10px 25px;
    justify-content: left;
  }
}

.mobile-header-left-side {
  display: none;
}

.logo-mobile {
  display: none;
}

.btn-collapse {
  display: none;

  @include media('<=laptop2') {
    display: flex;
    z-index: 1000;
    // color: #4dc1be;
    // background: $wht;
    color: $wht;
    background: #4dc1be;
    border: 1px solid $wht;
    box-sizing: border-box;
    border-radius: 4px;
    height: 30px;
    margin-left: -5px;
    width: 30px;
    justify-content: center;
    align-items: center;
    min-width: unset;
    @include transition(all 0.2s linear);

    &:hover {
      background: #c2dde0;
      border: 1px solid #4dc1be;
      color: #4dc1be;
    }

    &:active,
    &:focus {
      color: $wht;
      background: #4dc1be;
      border: 1px solid $wht;
    }
  }
}

.nav-menu-collapsed {
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  @include media('<=lg') {
    display: block;
    z-index: 1000;
    margin-top: 50px;
  }
}

.nav-display {
  display: block;

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  @include media('<=lg') {
    display: none;
  }
}

@media (max-width: 1200px) {
  .logo-desktop {
    display: none !important;
  }

  .mobile-header-left-side {
    display: flex;
    align-items: center;
  }

  .logo-mobile {
    display: block;

    .logo {
      margin: 6px 10px 10px 20px;

      img {
        max-height: 25px;
        width: 100%;
      }
    }
  }
}

@media (max-width: 600px) {
  .logo-mobile {
    .logo {
      img {
        height: 15px;
      }
    }
  }
}

@media (max-width: 380px) {
  .admin_header {
    .ant-dropdown-link {
      margin-right: 10px;
    }
  }
}

.ant-btn {
  height: 38px;
  letter-spacing: 0.05em;
  font-size: 13px;
  border-radius: 14px;
  min-width: 90px;

  .anticon {
    vertical-align: text-top;
  }
}

.ant-btn-sm {
  background: $wht;
  border: 1px solid #4dc1be;
  box-sizing: border-box;
  border-radius: 14px;
  width: 90px;
  height: 30px;
  @include transition(all 0.2s linear);

  span {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #4dc1be;
  }

  &:hover,
  &:focus {
    background: #c2dde0;
    border: 1px solid #4dc1be;
    box-sizing: border-box;
  }

  &:focus {
    border: 1px solid #ffffff;
  }
}

.ant-btn-primary {
  background-color: $c-primary;
  border-color: $c-primary;
  & span {
    color: white !important;
  }

  &:hover,
  &:focus {
    background-color: lighten($c-primary, 3);
    border-color: lighten($c-primary, 3);
  }

  &.ant-btn-sm {
    background-color: $wht;
    border: 1px solid #ff7373;
    span {
      color: #ff7373;
    }

    &:hover,
    &:focus {
      background: #e9cfcd;
      border: 1px solid red;
      box-sizing: border-box;
    }

    &:focus {
      border: 1px solid #ffffff;
    }
  }
}

.ant-btn-secondary:hover,
.ant-btn-secondary:focus {
  color: #4f66a0;
  background: #fff;
  border-color: #4f66a0;
}

.ant-btn-danger {
  background-color: $danger;
  color: #fff;
  border-color: transparent;

  &:focus {
    color: #fff;
    background-color: darken($danger, 5);
    border-color: transparent;
  }
}

.ant-popover-buttons button {
  margin-left: 15px;
}

.h-100 {
  height: 100%;
}

.mh-100 {
  min-height: 100%;
}

.pt-0 {
  padding-top: 0 !important;
}

.inline-flex {
  display: flex;
  justify-content: space-between;
}

.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

input[type='time'] {
  width: 70px;
  border: none;
  clip-path: inset(0 17px 0 0);
  outline: none;
  outline: 0;
}

@media all {
  .page-break {
    display: none;
  }
}

@page {
  size: auto;
  margin: 20mm 20mm 20mm 20mm;
}

//Doctors nav menu
.doctors-nav {
  display: none;
  height: 60px;
  position: fixed;
  width: 100%;

  @media (max-width: 992px) {
    display: flex;
  }

  .doctors-nav-content {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }

  .action-button {
    &.doctors-nav-button {
      border: 1px solid #4dc1be;
      height: 38px;
      border-radius: 20px;
      margin-top: 35px;
      margin-left: 10px;
    }
  }
}

//ordination form
.ordination-form-container {
  max-width: 700px;
  padding: 8px 22px;
  margin: 50px auto 42px;
  width: 100%;
  overflow: auto;

  &.wide {
    max-width: 1200px;
    display: flex;
  }

  @media (max-width: 1200px) {
    margin-left: auto;
  }
}

.ordination-form-wrapper {
  margin-right: 10px;
  width: calc(50% - 10px);

  &.wide {
    width: 100%;
    margin-right: 0;
  }

  .dashboard {
    .card-wrapper {
      position: relative;

      .show-specialization {
        position: absolute;
        top: 35px;
        left: 25px;
        z-index: 5;
        height: 30px;
      }

      .ant-card {
        width: 100%;
        border-radius: 13px;
        box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.25);

        // @media (max-width: 768px) {
        //   border-radius: unset;
        //   box-shadow: none;
        // }

        .ant-card-head {
          border-radius: 13px 13px 0 0;

          .ant-card-head-title {
            margin-bottom: 40px;
          }
        }
      }

      .ant-form-item-label {
        min-width: 140px;
      }
    }
  }

  .ant-card .ant-form-item {
    margin-bottom: 0;
  }
}

.ordination-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  &.ordination-form {
    justify-content: space-between;

    @media (max-width: 460px) {
      flex-direction: column;
    }

    .action-button {
      &.delete {
        margin-left: 0;
        margin-right: 10px;
        width: fit-content;

        @media (max-width: 460px) {
          margin-bottom: 10px;
        }
      }
    }

    .left-ordination-buttons {
      .action-button {
        &.border-dark {
          margin: 12px 0 0 0;
        }
      }
    }

    .right-ordination-buttons {
      display: flex;
      flex-direction: column;

      .action-button {
        &.border-dark {
          margin-right: 10px;
          margin-left: 0;

          @media (max-width: 460px) {
            margin: 12px 0 0 0;
            width: fit-content;
          }
        }

        &.delete {
          margin: 12px 0 0 0;
        }
      }
    }
  }

  .action-button {
    margin-right: 0;

    &.delete {
      border: 1px solid #ff7373;
      margin-left: 10px;

      span {
        color: #ff7373;
      }

      svg {
        color: #ff7373;
      }

      &:hover,
      &:focus {
        background: #e9cfcd;
        border: 1px solid #ff7373;
        box-sizing: border-box;
      }

      &:focus {
        border: 1px solid #ffffff;
      }
    }
  }
}

.add-specialization,
.show-specialization {
  background: $wht;
  border: 1px solid #4dc1be;
  box-sizing: border-box;
  border-radius: 14px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include transition(all 0.2s linear);
  margin-top: 20px;
  height: 30px;

  span {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #4dc1be;
  }

  &:hover,
  &:focus {
    background: #c2dde0;
    border: 1px solid #4dc1be;
    box-sizing: border-box;
  }

  &:focus {
    border: 1px solid #ffffff;
  }
}

.add-specialization {
  &.border-dark {
    border: 1px solid #4dc1be;
    min-width: 90px;

    img {
      margin-right: 5px;
    }
  }
}

//category action
.create-new-item-form-mobile {
  display: none;
}

.category-action-container {
  width: calc(50% - 10px);
  margin: 14px auto 42px;
  padding: 8px 22px;

  .actions-block {
    background-color: $wht;
    border-radius: 13px;
    box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
      border-radius: unset;
      box-shadow: none;
    }

    .add-specialization {
      margin-top: 0;
    }
  }

  .panel {
    border-radius: 13px;

    @media (max-width: 768px) {
      border-radius: unset;
    }
  }

  .panel-heading {
    background-color: #4dc1be;
    border-radius: 13px 13px 0 0;

    @media (max-width: 768px) {
      border-radius: unset;
    }
  }

  .panel-primary {
    box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.25);

    @media (max-width: 768px) {
      box-shadow: none;
    }

    .panel-body {
      border: 1px solid #e8e8e8;
      border-top: none;
      border-radius: 0 0 13px 13px;
      margin-top: 25px;

      @media (max-width: 768px) {
        border-radius: unset;
      }
    }
  }
}

.ant-select-selection-overflow {
  height: auto !important;
  width: 200px !important;
}

.rst__rowContents {
  color: #627b90;
}

.rst__rowTitle {
  font-weight: 400;
  font-size: 0.8rem;
  text-transform: capitalize;
}

.rst__rowSubtitle {
  margin-left: 0.5rem;
  font-size: 0.6rem;
}

.create-new-item-form {
  width: auto;
  position: fixed;
  top: 0;
  right: -500px;
  z-index: 101;
  display: flex;
  height: 100%;
  @include transition(right 0.7s ease-out);

  .panel-heading {
    background-color: #4dc1be;
    padding: 0.6rem;
  }

  .panel-primary {
    box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.25);

    .panel-body {
      border: 1px solid #e8e8e8;
      border-top: none;
      border: none;
      margin-top: 25px;
    }
  }

  &.active {
    right: 0;
  }
}

@media (max-width: 992px) {
  .ordination-form-container {
    padding: 8px 0;

    &.wide {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .ordination-form-wrapper {
    width: 90% !important;
    margin-right: 0;
    margin-left: 30px;
  }

  .category-action-container {
    width: 100%;
  }

  .create-new-item-form {
    display: none;
  }

  .create-new-item-form-mobile {
    display: block;
  }
}

@media (max-width: 768px) {
  .profile-empty-box {
    display: none;
  }

  .profile-image-box {
    margin: 0 0 0 10px;
  }
}

@media (max-width: 600px) {
  .profile-banner-box {
    flex-direction: column-reverse;
  }

  .profile-image-box {
    margin: 0;
  }

  .profile-left-box {
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }
}

@media (max-width: 576px) {
  .ordination-buttons {
    button {
      margin-left: 15px;
    }
  }

  .ordination-form-wrapper {
    margin-left: 20px;
  }
}

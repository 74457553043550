.ant-modal {
  padding-top: 24px;

  .anticon {
    vertical-align: 0 !important;
    // color: #35b7b9 !important;

    svg {
      width: 18px;
      height: 18px;
    }
  }
}

.doctor-modal {
  .ant-input[disabled] {
    background: $wht;
    // color: #35b7b9;
  }

  .ant-input {
    border: none;
    outline: none;
    padding: 5px;
    // color: #35b7b9;
    font-weight: 700;
    text-align: start !important;
  }

  .PhoneInput--disabled {
    background: $wht;
    // color: #35b7b9;
  }
}

.ant-modal {
  width: 554px;

  &.gallery-modal {
    .ant-modal-header {
      padding: 15px 17px;

      @media (max-width: 600px) {
        padding: 15px 3px;
      }
    }

    .ant-modal-body {
      @media (max-width: 600px) {
        padding: 0 10px;
      }
    }
  }

  .ant-modal-header {
    border-radius: 13px;
    border-bottom: 0;
  }

  .ant-modal-footer {
    border-top: 0;
    padding: 10px 24px;

    button {
      border: 1px solid #4dc1be;
    }
  }

  .ant-modal-title {
    text-align: left;
    color: #627b90;
    font-weight: 300;
    font-size: 20px;
    line-height: 28px;
    padding-left: 5px;
  }

  .ant-modal-content {
    border-radius: 13px;
    box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.25);

    margin: 0;
    // min-height: 737px;

    // @media (max-width: 768px) {
    //   border-radius: unset;
    //   box-shadow: none;
    // }
  }

  .ant-card {
    position: relative;
    border-radius: 13px;

    .modal-icon {
      position: absolute;
      top: 21px;
      left: 20px;
      height: 15px;

      @media (max-width: 600px) {
        left: 10px;
      }
    }
  }

  // .ant-card-head-wrapper {
  //   border-bottom: none;
  // }

  // .ant-card-head-title {
  //   padding: 0;
  // }

  .ant-card-body {
    padding: 0 24px;
    background-color: #fbfbfb;
    border-radius: 0 0 13px 13px;
  }

  .ant-modal-body {
    padding: 0 24px;
    position: relative;

    .ant-card {
      .ant-form-item {
        margin-bottom: 8px;
      }
    }

    .card-form {
      margin: 10px 0;
    }
  }

  .ant-form-item-control-input-content {
    border-bottom: 1px solid #e5e5e5;
    padding: 5px;

    .action-button {
      margin-right: -5px;
    }
  }

  // podesavanje vreme zakazivanja termina

  .time-value {
    .ant-form-item-control-input-content {
      padding: 0 !important;
      border: 0;
      display: flex;
      justify-content: center;
      display: grid;
      grid-template-columns: 1fr 30px 1fr;
      text-align: center;
    }

    & .ant-form-item-control-input-content {
      & > span {
        &:nth-child(1) {
          font-size: 16px;
          font-weight: 700;
          color: #35b7b9;
          border-bottom: 1px solid #e5e5e5;
          margin: 0 10px;
        }

        &:nth-child(2) {
          color: #939393;
          font-weight: 500;
          font-size: 14px;
        }

        &:nth-child(3) {
          font-size: 16px;
          font-weight: 700;
          color: #35b7b9;
          margin: 0 10px;
          border-bottom: 1px solid #e5e5e5;
        }
      }
    }
  }

  .modal-form-time {
    .ant-form-item-control-input-content {
      font-size: 16px;
      font-weight: 700;
      // color: #35b7b9;
      // margin: 0 10px;
      text-align: start;
    }
  }
  .modal-form-time-melo-green {
    .ant-form-item-control-input-content {
      font-size: 16px;
      font-weight: 700;
      color: #35b7b9;
      // margin: 0 10px;
      text-align: start;
    }
  }

  .date-value {
    .ant-form-item-control-input-content {
      padding: 0 !important;
      border: 0 !important;
      display: grid;
      grid-template-columns: auto;
      text-align: center;
    }

    span {
      padding: 2px !important;
      border-bottom: 1px solid #e5e5e5;
      font-size: 16px;
      font-weight: 700;
      color: #35b7b9;
    }
  }

  .ant-input {
    padding: 0;
    border-bottom: 0;

    &:focus,
    &:active {
      background-color: transparent;
    }
  }

  .ant-card-head-title {
    font-weight: 700;
    font-size: 24px;
    color: #35b7b9;
    background-color: #fbfbfb;
  }

  .action-button {
    float: right;

    &.action-edit {
      border: 1px solid #4dc1be;
    }

    &.delete-button {
      background-color: #fff;
      border: 1px solid #ff7373;
      margin-left: 10px px;
      margin-right: 0;

      span {
        color: #ff7373;
      }

      svg {
        margin-bottom: 1px;
        color: #ff7373;
      }

      &:hover,
      &:focus {
        background: #e9cfcd;
        border: 1px solid #ff7373;
        box-sizing: border-box;
      }

      &:focus {
        border: 1px solid #ffffff;
      }
    }
  }

  .ant-btn {
    background: $wht;
    border: 1px solid #c2dde0;
    box-sizing: border-box;
    border-radius: 14px;
    height: 30px;
    line-height: 22px;
    @include transition(all 0.2s linear);

    span {
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      color: #4dc1be;
    }

    &:hover,
    &:focus {
      background: #c2dde0;
      border: 1px solid #4dc1be;
      box-sizing: border-box;
    }

    &:focus {
      border: 1px solid #ffffff;
    }
  }

  .ant-tabs {
    overflow-y: auto !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #4dc1be;
  }

  .ant-tabs-tab-btn {
    color: #627b90;
  }

  .ant-input-group-addon {
    width: 24px;
  }

  .ant-tabs-ink-bar {
    background-color: #4dc1be;
  }

  .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
    color: #4dc1be;
  }

  .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: #4dc1be;
  }
}

.calendar-buttons {
  display: flex;
  justify-content: space-between;
  padding: 1.3rem 0;

  .calendar-save-button {
    .ant-form-item-control-input-content {
      border: none !important;
      padding: 0 !important;
    }
  }
}

.calendar-data-form-card {
  .ant-card-head {
    border-bottom: 1px solid #dadada;
    padding-bottom: 10px;

    // .ant-card-head-wrapper {
    //   .ant-card-head-title {
    //     margin-left: 20px !important;
    //   }
    // }
  }
}

.calendar-data-form-card {
  &.date-change {
    .ant-card-head-wrapper {
      .ant-card-head-title {
        margin-left: 0 !important;
      }
    }

    .ant-card-body {
      max-width: 555px;
      padding: 0 24px 15px 24px;
    }
  }
}

@media (max-width: 600px) {
  .ant-modal {
    .ant-card-head-title {
      font-size: 18px;
    }
  }

  .calendar-data-form-card {
    .ant-card-head {
      padding: 0 20px;
    }
  }

  .ant-card {
    &.calendar-data-form-card {
      .ant-card-body {
        padding: 0 10px;

        @media (max-width: 425px) {
          max-width: 215px;
        }
      }
    }
  }

  .calendar-data-form-card {
    &.date-change {
      .ant-card-body {
        padding: 0 24px 15px 24px;
      }
    }
  }
}

@media (max-width: 530px) {
  .calendar-buttons {
    & div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .patient-card {
    display: flex;
    flex-direction: column;

    & button {
      margin-bottom: 12px;
    }
  }

  .ant-modal {
    .action-button {
      &.action-edit {
        margin-right: 0px !important;
        margin-bottom: 12px;
      }
    }
  }
}

@media (min-width: 992px) {
  .ant-col-lg-offset-2 {
    margin-left: 0 !important;
    margin-right: 8% !important;
  }
}

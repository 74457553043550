form {
  .ant-input-group-wrapper {
    display: block;
    margin: 10px 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.ant-input {
  border: none;
  border-bottom: 1px solid #e5e5e5;
  color: #627b90;
  border-radius: 0;
  padding: 5px;
  font-weight: 700;

  &:focus,
  &:hover {
    border-bottom: 1px solid #e5e5e5;
    border-color: #d5d5d5;
    box-shadow: unset;
  }
}

.xxx {
  .ant-input {
    border: none;
    border-bottom: 1px solid #e5e5e5;
    color: #627b90;
    border-radius: 0;
    padding: 5px;
    font-weight: 700;
    text-align: center;

    &:focus,
    &:hover {
      border-bottom: 1px solid #e5e5e5;
      border-color: #d5d5d5;
      box-shadow: unset;
    }
  }
}

.ant-popover-message-title {
  font-weight: 700;
  font-size: 14px;
  color: #4dc1be;
}

.ant-select {
  border: none;
  color: #627b90;
  // color: #35b7b9;
  font-weight: 700 !important;
  font-size: 16px !important;
  text-align: start !important;
}

.ant-select-tree .ant-select-tree-node-content-wrapper {
  font-weight: 400;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
  border-bottom: 1px solid #e5e5e5;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #e5e5e5;
  box-shadow: unset;
}

.ant-input-group {
  font-size: 16px;

  > .ant-input {
    &:last-child {
      border-radius: 4px;
    }
  }

  .ant-calendar-picker-input {
    float: none;
  }
}

.ant-input-group-addon {
  padding-left: 0;
  border: none;
  border-radius: 0;

  &:first-child {
    background-color: transparent;
    color: inherit;
    display: block;
    font-size: 0.875em;
    font-weight: bold;
    width: 100% !important;
    margin-bottom: 0.5em;

    .form-horizontal & {
      width: 20% !important;
      text-align: right;
      display: table-cell;
      margin: 0;
    }
  }
}

.radno-naslov {
  .ant-card-head-title {
    margin-bottom: 15px !important;
  }
}

.ant-input-lg {
  font-size: 14px;
}

.ant-select-selection__rendered {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
}

.ant-select-lg {
  .ant-select-selection__rendered {
    font-size: 14px;
  }
}

.ant-select-tree-switcher {
  .anticon {
    vertical-align: inherit;
  }
}

.ant-select-tree-node-content-wrapper {
  vertical-align: middle;
}

.ant-select-selection__choice__remove {
  .anticon {
    vertical-align: text-top;
  }
}

textarea {
  width: 100%;
  border-radius: 4px;
  resize: vertical;
  height: 140px;
  overflow: auto;
  flex: 1 1 auto;
  border: 1px solid #d9d9d9;
  padding: 0.75em 11px;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  font-size: 14px;
  line-height: 1.5;
  font-family: 'Lato', sans-serif;

  &:hover,
  &:active,
  &:visited,
  &:focus {
    border-color: #40a9ff !important;
    outline: none;
  }
}

.ant-radio-inner:after {
  background-color: #4f66a0 !important;
}

.ant-input-group > .ant-input:not(:first-child):not(:last-child) {
  border-radius: 4px;
}

.ant-card-head-title {
  text-align: left;
  color: #35b7b9;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  padding-right: 5px;
}

// .ant-card-head-title {
//   text-align: left;
//   color: #627b90;
//   font-weight: 300;
//   font-size: 20px;
//   line-height: 28px;
//   padding-left: 5px;
//   padding-right: 5px;
// }

.ant-card-head {
  border-bottom: none;
  min-height: 30px;
  background-color: #fbfbfb !important;
}

.ant-card-head-wrapper {
  border-bottom: 1px solid #e5e5e5;
}

.ant-form {
  margin: 0 5px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 0;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #e5e5e5;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #e5e5e5;
  box-shadow: unset;
}

.ant-form-item-label > label {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.3px;
  display: flex;
  align-items: center;
  color: #627b90;
}

//background of selected dropdown option
.ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: #f5f5f5;
}

//smaller left margine on select dropdown options
.ant-select-tree .ant-select-tree-treenode {
  margin-left: -22px;
}

.ant-select-single.ant-select-open .ant-select-selection-item {
  // color: #35b7b9;
  font-weight: 700;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  // color: #35b7b9;
  font-weight: 700;
}

.ant-select-multiple .ant-select-selection-item-content {
  // color: #35b7b9;
  font-weight: 700;
}

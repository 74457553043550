.home-page-container {
  padding: 8px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 102px);
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.home-page-content-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  margin-bottom: -4%;
  z-index: 10;
}

.ant-card-head-title {
  margin: 0 !important;
}

.ant-table-thead tr .ant-table-cell,
.ant-btn-primary {
    background: #4dc1be !important;
    border-color: #4dc1be !important;
}

.home-page-buttons {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: -80px;

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    @include transition(color 0.2s linear);

    &:hover {
      span {
        color: $wht;
      }
    }

    span {
      margin-top: 10px;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      color: #627b90;
    }

    button {
      height: 80px;
      width: 80px;
      background: $wht;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $wht;
      @include transition(all 0.2s linear);

      &:hover,
      &:focus {
        background: #c2dde0;
        border: 1px solid #4dc1be;
        box-sizing: border-box;
      }

      &:focus {
        border: 1px solid #ffffff;
      }

      .ordination {
        width: 45px;
        height: 60px;
      }
    }
  }
}

.home-page-flowers {
  margin-bottom: -50px;
}

.home-page-base {
  width: 60%;

  img {
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .home-page-content-wrapper {
    width: 70%;
    margin-bottom: -2.5%;
  }

  .home-page-base {
    width: 80%;
    margin-top: -2.5%;
  }
}

@media (max-width: 768px) {

  .home-page-flowers {
      margin-bottom: -108px;
    }

  .home-page-doctors {
      margin-bottom: -50px;
    }

  .home-page-buttons {
    margin-bottom: -5%;
    margin-left: 130px;

    flex-direction: column;

    a {
      align-items: center;
      flex-direction: row;
      margin: 10px 0;

      span {
        text-align: left;
        font-size: 12px;
        margin-left: 15px;
        margin-top: 0;
      }

      button {
        height: 50px;
        width: 50px;

        .ordination {
          width: 23px;
          height: 30px;
        }
      }
    }
  }
}

@media (max-width: 680px) {
  .home-page-buttons {
      margin-left: 100px;
  }
}

@media (max-width: 586px) {
    .home-page-flowers {
      margin-bottom: -140px;
    }
  
    .home-page-doctors {
      margin-bottom: -100px;
    }

    .home-page-buttons {
      margin-left: 80px;
  }
}

@media (max-width: 500px) {
  .home-page-content-wrapper {
    width: 90%;
    margin-bottom: 0;
  }

  .home-page-base {
    width: 100%;
  }
}

@media (max-width: 400px) {
  .home-page-buttons {
    margin-bottom: 0;
  }

  .home-page-doctors,
  .home-page-flowers,
  .home-page-base {
    display: none;
  }
}

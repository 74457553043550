#calendar {
  width: 100%;
  padding: 30px 5px 0 0px;
  height: calc(100vh - 70px);

  @media (min-width: 1024px) {
    padding: 30px 5px 0 80px;
  }

  @media (max-width: 1200px) {
    padding: 30px 5px 0 0;
  }

  text-align: center;

  @media (max-width: 991px) {
    padding: 30px 5px 0 0;
  }
}

// full calendar
.fc .fc-timegrid-col.fc-day-today {
  background-color: white !important;
  //boja aktivnog dana
}

.fc {
  background-color: $wht;
  height: 100%;

  //visina polja u tabeli
  .fc-timegrid-slot {
    height: 31px;
    border: 0;
  }

  .fc-toolbar {
    display: flex;
    flex: 1 1 0;
    gap: 5px;
    // align-self: auto;
    // overflow: auto;

    &.fc-header-toolbar {
      margin-bottom: 30px;
      margin-top: 30px;
      background-color: $wht;

      @media (max-width: 1440px) {
        .fc-toolbar-chunk:nth-child(1) {
          text-align: center;
          min-width: 350px;
          max-width: fit-content;
        }

        .fc-toolbar-chunk:nth-child(2) {
          margin: 0 5px;
          min-width: 270px;
          max-width: 380px;
        }

        .fc-toolbar-chunk:nth-child(3) {
          margin: 0 5px;
          min-width: 205px;

          .fc-button-group {
            min-width: 205px;
          }
        }
      }

      @media (max-width: 1155px) {
        .fc-toolbar-chunk:nth-child(1) {
          text-align: center;
          min-width: 300px;
          max-width: fit-content;
        }

        .fc-toolbar-chunk:nth-child(2) {
          margin: 0 5px;
          min-width: 270px;
          max-width: 380px;
        }

        .fc-toolbar-chunk:nth-child(3) {
          margin: 0 5px;
          min-width: 180px;

          .fc-button-group {
            min-width: 180px;
          }
        }
      }

      @media (max-width: 1024px) {
        margin-top: 25px;
        margin-bottom: 25px;

        .fc-toolbar-chunk:nth-child(1) {
          text-align: center;
          max-width: 250px;
        }

        .fc-toolbar-chunk:nth-child(2) {
          margin: 0 5px;
          min-width: 270px;
          max-width: 270px;
        }

        .fc-toolbar-chunk:nth-child(3) {
          margin: 0 5px;

          .fc-button-group {
            width: 178px;
          }
        }
      }

      @media (max-width: 768px) {
        .fc-toolbar-chunk:nth-child(1) {
          font-size: 18px;
          text-align: center;
          min-width: 270px;
          max-width: 350px;
        }
      }

      @media (max-width: 540px) {
        margin: 0;
        display: grid;
        justify-content: center;

        .fc-toolbar-chunk:nth-child(2) {
          margin-bottom: 10px;
          margin-left: 60px;
        }
      }

      @media (max-width: 375px) {
        margin: 0;
        display: grid;

        .fc-toolbar-chunk:nth-child(2) {
          margin-bottom: 10px;
          margin-left: 40px;
        }
      }

      @media (max-width: 320px) {
        margin: 0;
        display: grid;

        .fc-toolbar-chunk:nth-child(1) {
          min-width: 250px;
        }

        .fc-toolbar-chunk:nth-child(2) {
          margin-bottom: 10px;
          margin-left: 15px;
        }
      }
    }

    .fc-toolbar-chunk {
      position: relative;
      right: 10px;

      @media (max-width: 425px) {
        left: 10px;
        right: 0;
        justify-content: space-evenly;
      }

      .fc-today-button {
        z-index: 2;
      }

      .fc-button-group {
        width: 205px;
        justify-content: space-between;
        z-index: 1;

        @media (max-width: 1155px) {
          width: 180px;
        }
      }

      &:nth-child(3) {
        width: 205px;
        height: 48px;

        .fc-button-group {
          position: absolute;
          top: 0;
          left: -12px;
        }

        .fc-today-button.fc-button.fc-button-primary {
          left: 47px;
        }
      }
    }
  }

  // .fc-col-header {
  //   height: 90px !important;
  // }

  .fc-day-today {
    background-color: #35b7b9;

    border-radius: 8px;
    color: $wht;
    padding: 0;
    margin: 0;

    .fc-col-header-cell-cushion,
    .active {
      color: $wht;
    }
  }

  // .fc-scrollgrid-sync-inner {
  //   margin-top: 3px;
  // }

  // DANI U NEDELJI U KALENDARU

  .fc-col-header-cell-cushion {
    font-size: 20px;
    line-height: 20px;
    font-weight: 400;
    font-family: Inter;
    color: #939393;
    padding: 5px 0 15px;
    text-decoration: none;
    pointer-events: none;
    word-spacing: 150px;
    text-transform: capitalize;

    &::first-line {
      font-weight: 600;
      line-height: 45px;
    }

    @media (min-width: 2229px) {
      word-spacing: 50px;
    }

    .fc-timegrid-slot-label {
      background-color: #fff;
    }

    .fc-timegrid-slot-label-cushion {
      color: #939393;
      font-size: 16px;
      font-weight: 600;
    }

    .fc-toolbar-title {
      display: flex;
      align-items: center;
      justify-content: center;
      top: 5px;
      margin-left: 46px;
      color: #34b8b9;
    }

    .fc-today-button {
      z-index: 2;
    }

    .fc-toolbar-chunk {
      .fc-button-group {
        margin-right: 40px;
        column-gap: 120px;
        z-index: 1;
      }
    }

    .fc-button-primary {
      display: flex;
      background: #34b8b9;
      border: 1px solid #34b8b9;
      border-radius: 6px;
      max-height: 80px;
      z-index: 2;

      @include transition(all 0.2s linear);

      &:hover,
      &:focus {
        background: #c2dde0;
        border: 1px solid #4dc1be;
        box-sizing: border-box;
        color: #4dc1be;
      }

      &:focus {
        border: 1px solid #ffffff;
      }

      &:disabled {
        border: 1px solid #4dc1be;
      }
    }

    .fc-timegrid-col {
      &.fc-day-today {
        background-color: $wht;
        border: 1px solid #c2d8eb;
        z-index: -1;
      }
    }
  }

  .fc-timegrid-bg-harness {
    border-bottom: 1px solid #dadada !important;
  }

  .fc-theme-standard {
    td,
    th,
    .fc-scrollgrid {
      border: 1px solid #c2d8eb;
    }

    // tbody {
    //   tr {
    //     td {
    //       &:first-child {
    //         border-bottom: 0 !important;
    //       }
    //     }
    //   }
    // }

    th {
      height: 90px;
      background-color: #fbfbfb;
      border: 0;
      border-bottom: 1px solid #dadada;

      &.active {
        .fc-col-header-cell-cushion {
          //today th text style
          &.active {
            background: #35b7b9;
            border-radius: 6px;
            color: #ffffff;
            padding: 15px 0 !important;
            margin: 0 !important;
            gap: 10px;
            width: 180px;
            height: 80px;
            font-size: 20px !important;
            font-weight: 600 !important;
            line-height: 100% !important;

            @media (max-width: 1679px) {
              padding: 6px 6px;
              width: 145px;
            }

            @media (max-width: 1389px) {
              padding: 6px 6px;
              width: 130px;
            }

            @media (max-width: 1269px) {
              padding: 6px 6px;
              width: 120px;
            }

            @media (max-width: 1180px) {
              padding: 6px 6px;
              width: 110px;
              font-size: 18px !important;
            }
          }
        }
      }
    }

    //remove table outer border
    .fc-scrollgrid {
      border: none !important;
    }

    //remove table outer border
    .fc-scrollgrid td:last-of-type {
      border-right: none !important;
    }
  }

  .fc-daygrid-body,
  .fc-daygrid-body-unbalanced,
  .fc-daygrid-body-natural {
    height: 16px !important;
  }

  .fc-timegrid-event .fc-event-main {
    padding: 0 !important;
  }

  .fc-v-event {
    border: none;

    .fc-event-title {
      color: #35b7b9;
      font-size: 13px;
    }
  }

  // .fc-timegrid-event {

  //   // color: #35b7b9;
  //   // font-weight: 600;
  //   // font-size: 14px;
  //   // background-color: transparent;

  // }

  .fc-event-time {
    color: #35b7b9;
  }
}

.fc .fc-bg-event .fc-event-title {
  // display: none !important;
  font-size: 0.7rem !important;
  color: gray !important;
  font-weight: normal;
  position: absolute;
  right: 0;
  bottom: 0;
}

.fc-timegrid-col-frame {
  border-top: 1px solid #dadada !important;
}

.new_background {
  background: rgb(0, 0, 0) !important;
  // background: rgba(200, 200, 200, 0.95) !important; //Deki
}

// .new_background {
//   background: orange !important; //Deki
// }

.signature-canvas {
  background: whitesmoke;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;
  background-color: #4dc1be;
  color: #f0f8ff;

  &:hover {
    background-color: #f0f8ff;
    color: #000;
  }
}

//calendar future event background color

.fc-timegrid-event {
  &.fc-v-event {
    &.fc-event {
      &.fc-event-draggable {
        &.fc-event-resizable {
          &.fc-event-start {
            &.fc-event-end {
              &.fc-event-future {
                background-color: #e7f4f4 !important;
                color: #35b7b9 !important;
                font-size: 16px !important;
                font-weight: 500 !important;
                font-family: Inter;
                line-height: 16px;
                width: calc(100% + 2px);
                border-radius: 6px 6px 6px 6px;
                padding: 3px 0 0 5px;
                gap: 5px;
                border-left: 6px solid #35b7b9;

                &.fc-event-title {
                  &.fc-sticky {
                    :first-child {
                      font-size: 16px;
                      font-weight: 500;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.fc-timegrid-bg-harness > .fc-event-future,
.fc-timegrid-bg-harness > .fc-event-past {
  cursor: pointer !important;
}

.ant-card-extra {
  position: absolute;
  // background: red;
  right: 10%;
  top: -4px;
  padding: 0;

  img {
    width: 18px;
    height: 18px;
  }
}

//razmak oko oznacenog nafta boja termina
.fc-direction-ltr .fc-timegrid-col-events {
  margin: 0 3px 0 0px;
}

//calendar past event background color
.fc-timegrid-event {
  &.fc-v-event {
    &.fc-event {
      &.fc-event-draggable {
        &.fc-event-resizable {
          &.fc-event-start {
            &.fc-event-end {
              &.fc-event-past {
                background-color: #e7f4f4 !important;
                color: #35b7b9 !important;
                font-size: 16px !important;
                font-weight: 500 !important;
                font-family: Inter;
                line-height: 16px;
                width: calc(100% + 2px);
                border-radius: 6px 6px 6px 6px;
                padding: 3px 0 0 5px;
                grid-gap: 5px;
                gap: 5px;
                border-left: 6px solid #35b7b9;
              }
              // &.fc-event-past {
              //   opacity: 0.6;
              //   background: repeating-linear-gradient(-45deg, #56959e, #56959e 5px, #77d2e6 5px, #77d2e6 25px);
              //   width: calc(100% + 3px);
              // }
            }
          }
        }
      }
    }
  }
}

//modal
.ant-modal {
  &.doctor-modal {
    &.patient {
      .ant-card-body {
        padding: 10px 24px;
        overflow: auto;

        @media (max-width: 600px) {
          padding: 10px 10px;
        }
      }
    }

    .ant-modal-body {
      padding: 20px 0 0 0;
      position: relative;
      background-color: #fbfbfb;
      border-radius: 14px;
    }
  }
}

.ant-form {
  &.ant-form-horizontal {
    &.modal-form {
      .ant-row {
        &.ant-form-item {
          margin-bottom: 4px;
        }
      }
    }
  }
}

.modal-form-time {
  color: #939393;
  font-weight: 500;
  font-size: 14px;
}

.edit-doctor-calendar-page {
  display: flex;

  .ant-layout-sider-dark {
    height: calc(100% + 18px);
    position: relative !important;
    margin-top: 0 !important;
    margin-top: -14px !important;
  }
}

.content-space-narrow {
  .edit-doctor-calendar-page {
    // margin-left: 226px;
    margin-top: 0;

    #calendar {
      @media (max-width: 992px) {
        padding: 84px 10px 0 10px;
      }
    }
  }
}

.doctor-name {
  font-weight: bold;
  margin-left: 1.1rem;
  margin-top: 1rem;

  @media (max-width: 500px) {
    font-size: 12px;
    position: fixed;
    top: 18px;
    left: 125px;
    font-weight: normal;
  }

  @media (max-width: 380px) {
    font-size: 12px;
    position: fixed;
    top: 24px;
    left: 125px;
    font-weight: normal;
  }
}

/* Removing input background colour for Chrome autocomplete */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #627b90 !important;
}

.calendar-disabled-inputs,
.patient-disabled-inputs {
  .ant-input,
  .ant-form-item-control-input-content {
    pointer-events: none;
  }
}

@media (max-width: 1200px) {
  .fc {
    .fc-timegrid-slot {
      height: 68px;
    }

    .fc-col-header-cell-cushion {
      font-size: 16px;
    }
  }
}

@media (max-width: 992px) {
  .fc-v-event {
    .fc-event-title {
      font-size: 12px;
    }
  }

  .fc-timegrid-event {
    font-size: 12px;
  }

  .fc {
    .fc-timegrid-slot {
      height: 58px;
    }
  }

  .content-space-narrow {
    .edit-doctor-calendar-page {
      margin-left: 0;
      margin-top: 0;
    }
  }
}

// @media (max-width: 895px) {
//   .fc-toolbar-title {
//     font-size: 20px;
//     margin-right: 5px;
//     text-align: center;
//   }
// }

@media (max-width: 768px) {
  .fc-v-event {
    .fc-event-title {
      font-size: 10px;
    }
  }

  .fc-timegrid-event {
    font-size: 10px;
  }

  .fc {
    .fc-col-header-cell-cushion {
      font-size: 12px;
    }
  }

  // .fc-toolbar-title {
  //   font-size: 18px;
  //   margin-right: 5px;
  //   text-align: center;
  // }

  .fc {
    .fc-timegrid-slot-label-cushion {
      font-size: 12px;
    }
  }
}

@media (max-width: 600px) {
  .fc-v-event {
    .fc-event-title {
      font-size: 9px;
    }
  }

  .fc-timegrid-event {
    font-size: 9px;
  }

  .fc {
    .fc-col-header-cell-cushion {
      font-size: 12px;
    }

    // .fc-toolbar-title {
    //   font-size: 18px;
    //   margin-right: 5px;
    //   text-align: center;
    // }

    .fc-button-group {
      margin-right: 10px;
    }
  }
}

@media (max-width: 500px) {
  .fc-v-event {
    .fc-event-title {
      font-size: 8px;
    }
  }

  .fc-timegrid-event {
    font-size: 8px;
  }

  .fc {
    .fc-col-header-cell-cushion {
      font-size: 10px;
    }

    // .fc-toolbar-title {
    //   font-size: 16px;
    //   margin-left: 5px;
    // }

    .fc-button-group {
      margin-right: 5px;
    }
  }
}

.fc-toolbar-chunk:nth-child(2) {
  padding: 0;
  background: #fbfbfb;
  border: 0.5px solid #dadada;
  color: #939393;
  border-radius: 10px;
  max-width: 450px;
  max-height: 49px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  align-items: center;
  overflow-x: auto;
  display: grid;
  grid-auto-columns: max-content;
  height: 50px;
  grid-auto-flow: column;
  margin-right: 5px;
}

.fc-toolbar-chunk:nth-child(2)::-webkit-scrollbar {
  width: 7px;
  height: 6px;
}

.fc-toolbar-chunk:nth-child(2) {
  scrollbar-width: thin;
  scrollbar-color: #35b7b9 $wht;
}
.fc-toolbar-chunk:nth-child(2)::-webkit-scrollbar-track {
  background: $wht;
}
.fc-toolbar-chunk:nth-child(2)::-webkit-scrollbar-thumb {
  background-color: #35b7b9;
  border-radius: 6px;
  // border: 3px solid red;
}

.single-ord {
  position: relative;
  padding: 10px;
  text-align: center;
  align-content: center;
  white-space: nowrap;
  line-height: 12px;
  // overflow-x: scroll;
  scroll-snap-type: proximity;
  margin-left: 5px !important;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 5px;
    bottom: 0;
    display: block;
    clear: both;
    width: 2px;
    height: 70%;
    background-color: #dadada;
    gap: 5px;
  }

  &:first-child {
    &::after {
      background-color: transparent;
    }
  }

  &:active {
    background: #35b7b9;
    border: 0px solid #4dc1be;
    border-radius: 8px;
    box-sizing: border-box;
    color: $wht;
  }

  &:hover,
  &:focus {
    background: #35b7b9;
    border: 0 solid #4dc1be;
    border-radius: 8px;
    box-sizing: border-box;
    color: $wht;
  }

  @media (max-width: 425px) {
    font-size: 14px;
  }
}

.single-ord-active {
  position: relative;
  padding: 10px;
  text-align: center;
  align-content: center;
  white-space: nowrap;
  line-height: 12px;
  // overflow-x: scroll;
  scroll-snap-type: proximity;
  margin-left: 5px !important;
  cursor: pointer;
  background: #35b7b9;
  border: 0px solid #4dc1be;
  border-radius: 8px;
  box-sizing: border-box;
  color: $wht;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 5px;
    bottom: 0;
    display: block;
    clear: both;
    width: 2px;
    height: 70%;
    background-color: #dadada;
    gap: 5px;
  }

  &:first-child {
    &::after {
      background-color: transparent;
    }
  }

  &:active {
    background: #35b7b9;
    border: 0px solid #4dc1be;
    border-radius: 8px;
    box-sizing: border-box;
    color: $wht;
  }

  &:hover,
  &:focus {
    background: #35b7b9;
    border: 0 solid #4dc1be;
    border-radius: 8px;
    box-sizing: border-box;
    color: $wht;
  }

  @media (max-width: 425px) {
    font-size: 14px;
  }
}

.line-span {
  margin-left: 20px;
  font-size: 28px;
  font-weight: 100;

  @media (max-width: 991px) {
    display: none;
  }
}

.clinic-name-dp {
  margin-left: 1rem;
  font-size: 20px;
  font-weight: 500;

  @media (max-width: 667px) {
    margin-left: 0;
    font-size: 18px;
  }

  @media (max-width: 590px) {
    margin: 0;
    font-size: 16px;
  }

  @media (max-width: 500px) {
    margin-left: 0;
    font-size: 14px;
    line-height: 14px;
    // position: fixed;
    // top: 18px;
    // left: 145px;
  }

  @media (max-width: 380px) {
    margin-left: 0rem;
    font-size: 14px;
    // position: fixed;
    // top: 4px;
    // left: 145px;
    // width: 50%;
    line-height: 14px;
  }
}

.kreiranje-klinike {
  .ant-select-single .ant-select-selector .ant-select-selection-search {
    left: 2px;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    margin-left: 5px;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    margin-left: 6px;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item {
    margin-left: 5px;
  }

  .PhoneInput {
    margin-left: 0px;
  }

  .PhoneInputCountry {
    margin-left: 7px;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    position: absolute;
    right: -7px;

    @media (max-width: 576px) {
      right: -15px;
    }
  }
}

.editovanje-klinike {
  .ant-select-single .ant-select-selector .ant-select-selection-search {
    left: 2px;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    margin-left: 0px;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    margin-left: 6px;
  }

  .PhoneInput {
    margin-left: 0px;
  }

  .PhoneInputCountry {
    margin-left: 5px;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    position: absolute;
    right: -7px;

    @media (max-width: 576px) {
      right: -15px;
    }
  }
}

.klinika-klinika {
  .PhoneInputCountry {
    margin-left: 3px;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-search {
    left: 0px !important;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    position: absolute;
    right: -7px;

    @media (max-width: 576px) {
      right: -15px;
    }
  }
}

.novi-lekar-form {
  .ant-form-horizontal .ant-form-item-control {
    margin-left: 5px;
  }

  .PhoneInputCountry {
    margin-left: 6px;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    position: absolute;
    right: -7px;

    @media (max-width: 576px) {
      right: -15px;
    }
  }
}

.edit-lekar-form {
  .ant-form-horizontal .ant-form-item-control {
    margin-left: 5px;
  }

  .PhoneInputCountry {
    margin-left: 6px;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    position: absolute;
    right: -7px;

    @media (max-width: 576px) {
      right: -15px;
    }
  }
}

.novi-pacijent-form {
  .PhoneInputCountry {
    margin-left: 6px;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    position: absolute;
    right: -7px;

    @media (max-width: 576px) {
      right: -15px;
    }
  }
}

.novi-lekar-klinika {
  .PhoneInputCountry {
    margin-left: 6px;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    position: absolute;
    right: -7px;

    @media (max-width: 576px) {
      right: -15px;
    }
  }
}

.novi-pacijent-profil {
  .patient-profile {
    .card-form.patient-edit-form .ant-col {
      min-width: 144px;
    }

    .dz .ant-form-item > .ant-row {
      flex-direction: column;
    }

    .PhoneInputCountry {
      margin-left: 6px;
    }

    .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
      margin-left: 5px;
    }

    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      position: absolute;
      right: -7px;

      @media (max-width: 576px) {
        right: -15px;
      }
    }
  }
}

.edit-pacijenta-profil {
  .patient-profile {
    .card-form.patient-edit-form .ant-col {
      min-width: 144px;
    }

    .dz .ant-form-item > .ant-row {
      flex-direction: column;
    }

    .PhoneInputCountry {
      margin-left: 6px;
    }

    .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
      margin-left: 4px;
    }

    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      position: absolute;
      right: -7px;

      @media (max-width: 576px) {
        right: -15px;
      }
    }
  }
}

.doktor-profil-forma {
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    position: absolute;
    right: -7px;

    @media (max-width: 576px) {
      right: -15px;
    }
  }
  .dashboard {
    .card-wrapper {
      .ant-form-item-label {
        min-width: 140px;
      }
    }
  }
}

.pacijent-view {
  .dz .ant-form-item > .ant-row {
    flex-direction: column;
  }

  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    margin-left: -5px;
  }

  .PhoneInputCountry {
    margin-left: 2px;
  }
}

.calendar-editable-inputs {
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    position: absolute;
    right: -7px;

    @media (max-width: 576px) {
      right: -15px;
    }
  }
}

.kalendar-termin {
  color: #35b7b9;
}

// CSS for pick calendar view
.dropdownContainer {
  position: relative;
  cursor: pointer;

  @media (max-width: 1024px) {
    padding-top: 10px;
  }

  // @media (max-width: 723px) {
  //   margin-top: 10px;
  // }

  // @media (max-width: 600px) {
  //   margin-top: 12px;
  // }

  @media (max-width: 425px) {
    margin: 10px 10px 10px 0;
  }
}

.dropdownElementFlex {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}

.dropdownViewContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  padding: 0 30px;
  border: 1px solid #dadada;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  color: #939393;
  margin: 0 10px;

  @media (max-width: 1275px) {
    font-size: 14px;
    margin: 0 5px;
    padding: 0 20px;
  }

  @media (max-width: 1024px) {
    font-size: 12px;
    margin: 0 5px;
  }

  @media (max-width: 425px) {
    font-size: 14px;
    margin: 0;
  }

  .dropdownIcon {
    margin-left: 20px;
  }
}

.toolBarTitle {
  align-self: center;
}

.dropdownOptions {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  border: 1px solid #dadada;
  border-radius: 8px;
  background-color: #fff;
  width: 120%;
  z-index: 20;
  margin-left: 20px;
  // text-align: center;
  box-shadow: 0px 0px 12px -5px rgba(0, 0, 0, 0.55);
  padding: 0 15px;
  color: #939393;
  font-size: 14px;
  // display: none;
  cursor: pointer;

  li {
    padding-bottom: 20px;

    &:first-child {
      padding-top: 20px;
    }
  }
}

.fc-timegrid-harness-inset {
  width: 5px;
  background-color: #35b7b9;
  border: 1px solid #35b7b9;
  border-radius: 8px 0 0 8px;
  z-index: 20;
}

.fc-theme-standard th {
  border: none;
  border-bottom: 1px solid #ddd;
}

.fc-timegrid-axis,
.fc-timegrid-axis-frame td {
  border-bottom: none;
}

.fc th {
  @media (max-width: 320px) {
    word-break: break-word;
    font-size: 6px;
  }
}

.action-button-patient-card {
  background: #fff;
  border: 1px solid #35b7b9;
  color: #35b7b9;
  margin: 0;
  line-height: 16px;
  text-align: center;
  font-weight: 500;
  box-sizing: border-box;
  border-radius: 8px;
  min-width: 90px;
  width: 230px;
  height: 45px;
  padding: 15px 35px;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include transition(all 0.2s linear);

  &.delete-button {
    background-color: #35b7b9;
    color: #fff !important;
    // border: 1px solid #ff7373;
    margin-left: 12px;
  }

  @media (max-width: 425px) {
    width: 180px;
    min-inline-size: max-content;
  }

  // span {
  //   font-weight: 700;
  //   font-size: 14px;
  //   line-height: 16px;
  //   color: #35b7b9;
  // }

  &:hover,
  &:focus {
    background: #c2dde0;
    box-sizing: border-box;
  }
}

.ant-row,
.calendar-buttons {
  flex-flow: nowrap;
}

.lista-pacijenta {
  width: 450px;

  .pacijent-ime {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    color: #35b7b9;
  }

  .pacijent-telefon {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #939393;
    padding-bottom: 10px;
    border-bottom: 1px solid #dadada;
  }
}

.search-patient {
  .pretraga {
    border: 1px solid #dadada;
    border-radius: 8px;
    width: 450px;
    max-width: 500px;
    color: #35b7b9;
    font-weight: 600;
    margin: 15px 0;

    &:focus,
    &:hover {
      border: 1px solid #dadada !important;
      border-radius: 8px;
      border-bottom: 0;
    }
  }
}

//Stavljeno je u okviru ove parent klase kako bi se odnosilo iskljucivo na zakazivanje novog termina modal i ne bi ove izmene povlacilo na ostalim antd tabovima
.doctor-modal {
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    background: $wht;
    padding: 2px;
    border: 0.5px solid #dadada;
    border-radius: 7px;
  }
  .ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
    min-width: 231px;
    display: flex;
    justify-content: center;
    border-radius: 7px;
  }

  .ant-tabs-large > .ant-tabs-nav .ant-tabs-tab-active {
    background: #35b7b9;
    border: 0 solid #4dc1be;
    border-radius: 8px;
    box-sizing: border-box;
    .ant-tabs-tab-btn {
      color: $wht;
    }
  }
}

//Zakazivanje novog termina modal
.ant-modal .ant-tabs-ink-bar {
  background: none;
}

.ant-card-head-wrapper .ant-card-head-title {
  padding-top: 25px !important;
}

.ant-tabs-tab .ant-tabs-tab-btn {
  color: #627b90;
  font-weight: 500;
}

.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding: 10px;
  position: relative;
  text-align: center;
  align-content: center;
  white-space: nowrap;
  line-height: 18px;
  scroll-snap-type: proximity;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 5px;
    bottom: 0;
    display: block;
    clear: both;
    width: 2px;
    height: 70%;
    background-color: #dadada;
    gap: 5px;
  }

  &:first-child {
    &::after {
      background-color: transparent;
    }
  }

  &:active {
    background: #35b7b9;
    border: 0px solid #4dc1be;
    border-radius: 8px;
    box-sizing: border-box;
    line-height: 18px;
    color: $wht;
  }

  &:hover &:focus {
    background: #35b7b9;
    border: 0 solid #4dc1be;
    border-radius: 8px;
    box-sizing: border-box;
    color: $wht !important;
  }
}

.zakazivanje-novog-termina-search {
  background: #ffffff;
  border: 1px solid #dadada;
  border-radius: 8px;
  .ant-input {
    text-align: start !important;
  }
}

.zakazivanje-novog-termina-pacijenti {
  .zakazivanje-novog-termina-pacijent {
    cursor: pointer;
    background: #ffffff;
    border-bottom: 0.5px solid #dadada;
    padding: 5px;
    span:first-child {
      color: #35b7b9;
    }

    &:first-child {
      border-top: 0.5px solid #dadada;
      border-bottom: 0.5px solid #dadada;
    }
    &:last-child {
      border-bottom: 0.5px solid #dadada;
    }
    p {
      margin-bottom: 0;
      color: #627b90;
    }
  }
  &::-webkit-scrollbar {
    width: 7px;
  }
  & {
    scrollbar-width: thin;
    scrollbar-color: #35b7b9 $wht;
  }
  &::-webkit-scrollbar-track {
    margin-top: 2px;
    background: $wht;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #35b7b9;
    border-radius: 6px;
    // border: 3px solid red;
  }
}

.ant-tabs-tab + .ant-tabs-tab {
  margin: 0;
}

.xxx,
.ant-card-head-wrapper {
  padding: 0 0 10px 0;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.408px;
  color: #35b7b9;

  .ant-tabs-nav::before,
  .ant-tabs-nav::after {
    border-bottom: 0;
  }

  .ant-card-head-title {
    padding: 0;
  }
}

.xxx,
.ant-tabs-nav-wrap {
  display: flex;
  justify-content: space-evenly;
  margin: 25px 0;
}

.xxx {
  .ant-tabs-tab {
    width: 230px;
    height: 38px;
    padding: 10px 0;
    margin: 4px !important;
    display: flex;
    justify-content: center;
    text-align: center;
    color: #35b7b9;
  }

  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    background-color: transparent !important;
  }

  .ant-tabs-tab-active {
    border: 1px solid #35b7b9;
    background-color: #35b7b9;
    color: $wht !important;
    border-radius: 7px;
    padding: 10px 0;

    & .ant-tabs-tab-btn {
      color: $wht !important;
    }
  }
}

.xxx .ant-tabs-nav-list {
  border: 1px solid #dadada;
  border-radius: 8px;
  min-width: 478px;
  justify-content: space-evenly;
  // overflow-x: auto;
  padding: 0 10px;

  :active {
    background: #35b7b9;
    border: 0px solid #4dc1be;
    border-radius: 8px;
    box-sizing: border-box;
    color: $wht;
    line-height: 18px;
  }

  :hover,
  :focus {
    background: #35b7b9;
    border: 0 solid #4dc1be;
    border-radius: 8px;
    box-sizing: border-box;
    color: $wht;
  }
}

.xxx {
  border: 0;
}

.xxx .ant-form-item-control-input-content .ant-input {
  color: #35b7b9;
}

// .xxx .ant-modal-body {
//   height: 600px;
// }

.xxx .ant-modal-wrap .ant-modal-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-card.login-header.ant-form-item-control-input-content.ant-input {
  color: #627b90;
}

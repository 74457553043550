html {
  min-height: 100%;
  height: 100%;
}

body {
  background: #4dc1be;
  min-width: 320px;
  height: 100%;
}

#__next {
  height: 100%;
}

.ant-layout-sider-dark {
  background-color: $wht;
  position: fixed;
  z-index: 2;
  height: calc(100vh - 30px);
}

#admin-layout {
  height: 100%;
  overflow: hidden;

  section {
    padding: 20px 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    &.ant-layout {
      padding: 0;
      overflow: hidden;
    }
  }

  .ant-layout-sider {
    background: #2c2738;
    min-height: 100vh;
    z-index: 150;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .ant-layout-content {
    padding: 15px 15px 0;
    overflow-x: hidden;
    overflow-y: auto;

    > *:last-child {
      margin-bottom: 15px;
    }
  }
}

.header.ant-layout-header {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  span {
    margin-top: 10px;
  }
}

.jodit-workplace {
  min-height: unset !important;
  height: 300px !important;
}

.admin_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 25px 40px;
  gap: 20px;

  position: absolute;

  @include media('<=lg') {
    padding-left: 15px !important;
  }
}

header.ant-layout-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  // padding: 25px 40px;
  padding: 25px 10px;
  gap: 20px;
  position: absolute;
  height: 80px;
  left: 0;
  top: 0;
  justify-content: space-between;

  @include media('<=lg') {
    flex-direction: row;
    z-index: 100 !important;

    @media (max-width: 550px) {
      width: 90%;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 150px 1fr 120px;
      padding-right: 5px;
      text-align: center;
    }

    @media (max-width: 550px) {
      width: 90%;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 150px 1fr 40px;
      padding-right: 5px;
      text-align: center;
    }

    @media (max-width: 420px) {
      padding: 25px 5px;
      grid-template-columns: auto 1fr 30px;
    }

    align-items: center;

    > button {
      margin-right: auto;
    }

    .ant-dropdown-link {
      margin-left: auto;
    }
  }

  @media (max-width: 992px) {
    justify-content: baseline;
    margin: 0 auto;
  }

  span {
    margin-top: 10px;
  }
}

header.ant-layout-header {
  background-color: #4dc1be;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background: transparent;
}

.content-space {
  margin-top: 72px;
  margin-left: 265px;
  position: relative;
}

.content-space-narrow {
  margin-top: 50px;
  margin-left: 40px;

  @media (max-width: 1200px) {
    margin-left: 0;
  }

  &.admin-card-left-space {
    margin-left: 120px;
    margin-right: 0px;

    @media (max-width: 1200px) {
      margin-left: 0;
      position: relative;
    }
  }
}

.header-left-side-content {
  display: flex;
  align-items: center;

  .line-span {
    margin: 0 10px;
    font-size: 22px;
  }
}

.profile-image-wrapper {
  display: flex;
  align-items: center;

  .avatar {
    width: 36px;
    height: 36px;
    background-color: #cccccc;
    border-radius: 50%;
    position: relative;
    margin-right: 5px;

    & > img {
      margin-top: -27px;
      border-radius: 50%;
    }
  }

  & > img {
    margin-left: 10px;
    cursor: pointer;
    @include transition(all 0.2s linear);

    &:hover {
      filter: invert(54%) sepia(12%) saturate(2040%) hue-rotate(139deg) brightness(94%) contrast(85%);
    }
  }
}

.header-name {
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  margin-left: 10px;
  margin-bottom: 0;

  @media (max-width: 550px) {
    display: none;
  }
}

.current-doctor {
  display: flex;
  margin: 0 10px 0 100px;
  cursor: pointer;

  @include media('<=lg') {
    margin-left: 0;
  }

  .header-name {
    font-weight: 700;
    font-size: 20px;
    line-height: 16px;
    color: $wht;
  }
}

.ant-menu-item {
  padding-left: 20px !important;
}

.ant-menu-submenu-title {
  padding-left: 3px !important;
  padding-right: 24px !important;
}

.ant-menu-submenu-arrow {
  width: 0px !important;
}

.ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
  color: #4dc1be;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  @include transition(color 0.2s linear);

  &:hover {
    color: #ff7373;
  }
}

.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span > a {
  color: #627b90;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  @include transition(color 0.2s linear);

  &:hover,
  &:active {
    color: #ff7373;
  }
}

.ant-menu-dark .ant-menu-item-selected > span > a {
  color: #ff7373;
}

.doctor-link {
  color: #627b90 !important;
  @include transition(color 0.2s all);

  &.red {
    color: #ff7373 !important;
  }

  &:hover {
    color: #ff7373 !important;
  }
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: transparent;
}

.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  background-color: #4dc1be;
}

.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  background-color: #4dc1be;
}

.ant-menu-submenu,
.ant-menu-submenu-inline {
  border-top: 1px solid #e7f5ff;
  width: 90%;
  margin: 0 auto;
}

.table-footer {
  width: 100%;
  background-color: #c2dde0;
  height: 30px;
  z-index: 100;
  position: fixed;
  bottom: 0;
}

.legend {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: 550px) {
    width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &-section {
    display: flex;
    flex-direction: row;
    margin-right: 1rem;
    align-items: center;

    .color {
      height: 16px;
      width: 16px;
      margin-right: 0.3rem;
      border-radius: 50%;

      @media (max-width: 550px) {
        height: 10px;
        width: 10px;
      }
    }

    .red {
      background: #ff7373;
    }

    .blue {
      background-color: #ecabab;
    }

    .green {
      background: #7ad3d3;
    }

    .purple {
      background: #cf91d9;
    }

    & p {
      text-transform: uppercase;
      font-size: 0.85rem;
      margin: 0;

      @media (max-width: 550px) {
        font-size: 12px;
      }
    }
  }
}

.ant-layout {
  background-color: #f5f5f5;
}

@media (max-width: 992px) {
  .content-space {
    margin-left: 40px;
  }

  .overlay {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    margin-top: -14px;
    background: rgba(0, 0, 0, 0.4);
    height: 100%;
    width: 100%;
  }
}

@media (max-width: 520px) {
  .header-name {
    display: none;
  }
}

@media (max-width: 600px) {
  .profile-image-wrapper {
    .avatar {
      display: none;
    }
  }

  .current-doctor {
    .header-name {
      font-size: 16px;
    }
  }
}

@media (max-width: 420px) {
  .header-name {
    font-size: 13px;
  }

  .current-doctor {
    .header-name {
      font-size: 13px;
    }
  }
}

.recepie {
  &-title {
    text-align: center;
    color: #627b90;
    font-size: 1.5rem;
    font-weight: bold;
  }
  &-input {
    display: flex;
    margin-bottom: 2px;

    &.diagnose {
      margin-top: 10px;
    }

    label {
      display: block;
      max-width: 140px;
      width: 100%;
      color: #627b90;

      &.diagnose-modal-label {
        max-width: 225px;
        color: #000000;
        opacity: 0.8;
        font-weight: 500;
        font-size: 16px;
      }

      &.pharmacy-modal-label {
        max-width: 300px;
      }

      &.modal-label-wider {
        max-width: 170px;
      }
    }
    input {
      outline: none;
      width: 100%;
      border: none;
      border-bottom: 1px solid #e5e5e5;
      color: #627b90;
      border-radius: 0;
      padding: 2px;
      font-weight: 700;
    }
  }

  textarea {
    padding: 2px;
    border: none;
    border-bottom: 1px solid #e5e5e5;
    height: 80px;

    &.recepie-text {
      &.note {
        height: 60px;
        margin-bottom: 10px;
      }
    }
  }

  &-form {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    flex-wrap: wrap;

    label {
      color: #627b90;
      padding: 0 8px;
    }

    input[type='radio'] {
      width: 16px;
      height: 16px;
      border-color: #d9d9d9;
    }
  }
}

@media (max-width: 575px) {
  .recepie {
    &-input {
      flex-direction: column;
    }
  }
}

@media print {
  .recepie {
    &-input {
      flex-direction: row !important;
    }
  }
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap');

$bgColor: #fff;
$bodyColor: #f0f2f5;
$wht: #fff;

$k2: #fafafa;
$k3: #f7f7f7;
$k5: #f2f2f2;
$k10: #e6e6e6;
$k15: #d9d9d9;
$k20: #ccc;
$k25: #bfbfbf;
$k30: #b3b3b3;
$k40: #999;
$k50: #808080;
$k60: #666;
$k70: #4d4d4d;
$k80: #333;
$k90: #191919;
$k100: #000;

$base: #0071bc;
$txtColor: #696c74;

$c-primary: #216c97;
$c-secondary: #dbe2ea;
$c-tertiary: #0092dd;

$line: #ddd;
$darkBlue: #2e3192;
$hl: #ffcc00;

$highlight: #5bbfde;
$danger: #d9534f;

$success: #a1ca51;
$error: #ce0000;

$purple: #444573;
$red: $error;
$yellow: #d5c93f;
$orange: orange;
$green: $success;
$gray: #5d737e;

$font-stack: 'Gotham Book', 'Inter', sans-serif;
.banners-page {
  .ant-table-tbody {
    line-height: 2.5715;
  }


}

.ant-col.banner-individual {
  &.ant-col-xs-24.ant-col-md-12 {
    // background: grey;
    padding: 10px;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    margin: 12px;
    // box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.25);
  }
}


.custom-row {
  .ant-form-item:first-child {
    .ant-form-item-control-input-content {
      border-bottom: 0;
    }
  }
}

@media (max-width: 768px) {
  .custom-row {
    margin-right: 14px !important;
  }
}
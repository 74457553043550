.ant-table {
  font-size: 1em;
}

.ant-table table {
  border: 0 !important;
  border-radius: 2px;

  tbody {
    > tr {
      background-color: #f5f5f5;

      &:nth-child(2n + 1) {
        background-color: #fff;
        // background-color: rgba(242, 255, 255, 0.7);
      }
    }
  }
}

.ant-table-thead > tr > th {
  background-color: $c-primary;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, sans-serif;

  &.ant-table-column-has-actions {
    &.ant-table-column-has-sorters:hover {
      background-clip: padding-box;
      background-color: darken($c-primary, 3);
    }

    &.ant-table-column-has-filters {
      &:hover {
        .ant-table-filter-icon:hover,
        .anticon-filter:hover {
          background-color: darken($c-primary, 3);
          color: #fff;
        }
      }

      .anticon-filter {
        &.ant-table-filter-open {
          background-color: darken($c-primary, 3);
          color: #fff;
        }
      }

      .ant-table-filter-icon.ant-table-filter-open {
        background-color: darken($c-primary, 3);
        color: #fff;
      }
    }
  }

  &.ant-table-column-sort {
    background-color: darken($c-primary, 3);
  }

  .ant-table-column-sorter {
    color: #fff;

    &-down.on,
    &-up.on {
      color: yellow;
    }
  }

  .ant-table-filter-selected.anticon-filter {
    color: yellow;
  }
}

.ant-table-bordered {
  .ant-table-thead > tr > th {
    border-right-color: lighten($c-primary, 5);
  }
  .ant-table-tbody > tr > td {
    border-right: 0;
    border-right: 1px solid white;
  }
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  border-bottom: 0;
  border-right: 1px solid white;
  padding: 12px;
  font-size: 13px;
}

.ant-table-thead > tr > th {
  border-right-color: lighten($c-primary, 5);
}

.ant-table-bordered .ant-table-thead > tr > th:last-child,
.ant-table-bordered .ant-table-tbody > tr > td:last-child {
  border-right: 0 !important;
}

.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):nth-child(2n) > td {
  background-color: #f0f1f4;
}

.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background-color: #fcfcfd;
}

.ant-table-thead > tr > th .ant-table-filter-icon,
.ant-table-thead > tr > th .anticon-filter {
  color: #ffffff;
}

@media only screen and (max-width: 1500px) {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    font-size: 12px;
    padding: 12px 6px !important;
  }
  i.controls::before {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1250px) {
  .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters {
    padding-right: 22px !important;
  }

  .ant-table-thead > tr > th {
    font-size: 10px !important;
  }
}

tr.ant-table-expanded-row td > .ant-table-wrapper {
  margin: 0;
}

.table-actions {
  a {
    &:hover {
      opacity: 0.5;
    }
  }
}

.ant-table-filter-dropdown-link {
  &:hover {
    cursor: pointer;
  }
}

button {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  user-select: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

button.disabled {
  cursor: not-allowed;
}

.button-row {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin: 1.625em -0.375em;
  user-select: none;

  > * {
    margin: 0.375em;
  }

  &.center {
    justify-content: center;
  }

  .r-align,
  &.text-right > * {
    @include media('>=sm') {
      margin-left: auto;
    }
  }
}

.close-sider {
  color: #4dc1be;
  position: absolute;
  right: 20px;
  top: 20px;
  padding-bottom: 10px;
}

@media (min-width: 993px) {
  .close-sider {
    display: none;
    visibility: hidden;
  }
  .search-input {
    padding-top: 0 !important;
  }
}

.log {
  height: 100vh;
  position: relative;

  footer {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: $wht;
  }
}

.welcome {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: $wht;
  margin: 32px 0 42px 0;
}

.login-with {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: $wht;
}

.login-google {
  margin: 10px 0 17px 0;
}

.warning-sign  {
  color: orangered !important;
  cursor: pointer;
  padding: 0px 4px !important;
  border-width: 0 !important;
  margin-right: '0.5rem';
}

//antd
.ant-card {
  &.login-header {
    background-color: transparent;
  }

  .ant-form-item {
    margin-bottom: 16px;
  }

  .ant-input-affix-wrapper {
    max-width: 330px;
    height: 40px;
  }

  .login-button {
    text-transform: unset;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #4dc1be;
    background-color: $wht;
    border: 1px solid #c2dde0;
    border-radius: 14px;
    margin: 12px 0 26px 0;
    height: 30px;
    @include transition(all 0.2s linear);

    &:hover,
    &:focus {
      background: #c2dde0;
      border: 1px solid #4dc1be;
      box-sizing: border-box;
    }

    &:focus {
      border: 1px solid #ffffff;
    }
  }
}

.input-icon {
  margin-right: 16px;
  width: 20px;
}

input {
  &::selection {
    background: #c2dde0;
  }
}

.user-link {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: $wht;
  @include transition(color 0.2s linear);

  span,
  a {
    font-weight: 700;
  }

  &:hover {
    color: $wht !important;
  }
}

//registration
.registration-title {
  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
}


@media (max-width: 768px) {
  .welcome {
    margin: 20px 0 20px 0;
  }
}

@media (max-width: 600px) {
  .logo-image {
    width: 180px;
  }
}
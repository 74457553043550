.all-doctors {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 30px;
  gap: 30px;
  margin-right: -30px;
  margin-bottom: 30px;
  justify-content: center;
}

.category-doctors {
  display: flex;
  flex-direction: column;
  width: calc(12.5% - 30px);
}

.category-name {
  margin-top: 25px;
  cursor: pointer;
  color: #4dc1be;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
}

.doctors-dropdown-menu {
  display: none;

  &.active {
    display: block;
    margin-top: 15px;

    ul {
      margin-bottom: 5px;
      margin-top: 5px;

      li {
        line-height: 25px;

        a {
          cursor: pointer;
          color: #627b90;
          font-weight: 400;
          font-size: 14px;
        }
      }
    }
  }
}

.DayPicker {
  &.non-working-days-day-picker {
    .DayPicker-wrapper {
      .DayPicker-NavBar {
        .DayPicker-NavButton {
          top: 0;
        }
      }

      .DayPicker-Months {
        .DayPicker-Month {
          margin: 0;

          .DayPicker-Caption {
            padding: 0;
          }
        }
      }
    }
  }
}

.non-working-days {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  .doctor-working-hours {
    display: flex;
    justify-content: flex-start;

    .doctor-working-hours-field {
      margin-right: 10px;
      width: 128px;

      .ant-picker {
        &.ant-picker-borderless {
          padding: 0;

          .ant-picker-input > input {
            color: #627b90;
            font-weight: 700;
          }
        }
      }

      .ant-checkbox-wrapper {
        margin-right: 0.5rem !important;

        .ant-checkbox-checked {
          .ant-checkbox-inner {
            background-color: #4dc1be !important;
            border-color: #4dc1be !important;
          }
        }

        .ant-checkbox {
          &:hover {
            border-color: #4dc1be !important;
            background-color: #4dc1be !important;
          }
        }
      }
    }
  }
}

.doctor-working-hours {
  display: flex;
  justify-content: space-around;

  @media (max-width: 450px) {
    flex-direction: column;
    max-width: 160px;
    width: 100%;
  }

  .doctor-working-hours-field {
    display: flex;
    align-items: center;

    .doctor-working-hours-time {
      color: #627b90;
      margin-right: 0.5rem;
    }
  }
}

.doctor-working-hours-buttons {
  display: flex;
  justify-content: flex-end;

  .action-button {
    margin-top: 10px;

    &.delete {
      background-color: $wht;
      border: 1px solid #ff7373;
      margin-left: 10px;
      margin-right: 0;
      // margin-top: 10px;

      &:hover {
        background: #e9cfcd;
        border: 1px solid #ff7373;
        box-sizing: border-box;
      }

      &:focus {
        border: 1px solid #ffffff;
        background: #e9cfcd;
        box-sizing: border-box;
      }

      span,
      img {
        color: #ff7373;
      }
    }

    &.border-dark {
      margin-right: 0;
    }
  }
}

.ant-input-affix-wrapper-focused {
  box-shadow: none;
}

.ant-tabs-ink-bar {
  background-color: #4dc1be;
}

.doctor-working-hours-field {
  .ant-input-affix-wrapper {
    input[type='time'] {
      width: 100px;
    }
  }
}

@media (max-width: 1200px) {
  .category-doctors {
    width: calc(16.7% - 30px);
  }
}

@media (max-width: 992px) {
  .category-doctors {
    width: calc(25% - 30px);
  }
}

@media (max-width: 768px) {
  .category-doctors {
    width: calc(33.33% - 30px);
  }
}

@media (max-width: 600px) {
  .category-doctors {
    width: calc(50% - 30px);
  }
}

@media (max-width: 400px) {
  .category-doctors {
    width: 100%;
    align-items: center;
    margin-right: 30px;
  }
}

//jedna od dve
// .fc-day.fc-day-past,
.fc-non-business {
  background-color: #f5f5f5 !important;
  // background-color: rgba(138, 138, 138, 0.55) !important;
}

//jedna od dve
.fc-bg-event.fc-event.fc-event-end.fc-event-past {
  background-color: rgba(39, 39, 39, 0.1) !important;
}

//danasnji dan plus svi buduci
// .fc-day {
//   background-color: palevioletred !important;
// }

//svi prosli dani
// .fc-day.fc-day-past {
//   background-color: palevioletred !important;
// }

//svi dani koji nisu radni proslost i buducnost
// .fc-non-business {
//   background-color: palevioletred !important;
// }

// .fc-day.fc-day-past,
// .fc-non-business {
//   background-color: orange !important;
// }

//radno vreme
// .fc .fc-bg-event {
//   opacity: 1;
//   background: orange !important;
// }

.fc-event-main-frame {
  padding: 0.2rem !important;
}

.fc-event-time,
.fc-event-title-container .fc-event-title {
  color: #35b7b9;
}

.fc-bg-event .fc-event-title {
  color: #dadada !important;
}

.fc-col-header-cell-cushion {
  font-size: 0.9rem;
}

.fc-toolbar-title {
  font-size: 32px;
  font-weight: 600 !important;
  line-height: 32px !important;
  font-family: Inter !important;
  color: #35b7b9;
  margin-left: 20px;
  text-transform: capitalize;

  @media (max-width: 1440px) {
    font-size: 24px;
    margin-left: 0;
  }

  @media (max-width: 1295px) {
    font-size: 22px;
  }

  @media (max-width: 1270px) {
    font-size: 20px;
  }

  @media (max-width: 1160px) {
    font-size: 18px;
  }

  @media (max-width: 425px) {
    font-size: 18px;
    padding-top: 5px;
  }

  @media (max-width: 320px) {
    font-size: 18px;
    margin: 0;
    padding-top: 5px;
    max-width: 130px;
  }

  // @media (max-width: 1255px) {
  //   font-size: 16px;
  // }

  // @media (max-width: 1225px) {
  //   font-size: 14px;
  // }
}

.fc-today-button.fc-button.fc-button-primary {
  display: inline-flex;
  position: relative;
  left: 170px;
  order: 1;
  flex-grow: 0;
  background: #fbfbfb !important;
  border: 1px solid #dadada !important;
  color: #939393 !important;
  border-radius: 8px !important;
  min-width: 108px;
  height: 48px;
  padding: 16px 30px;
  gap: 10px;
  align-items: center;
  justify-content: center;
  font-weight: bold !important;
  transition: all 0.2s linear !important;

  &:hover {
    background: #4dc1be !important;
    color: #fff !important;
  }

  @media (max-width: 1155px) {
    padding: 16px;
    min-width: 84px;
  }

  @media (max-width: 1024px) {
    padding: 16px 16px;
    min-width: 84px;
  }
}

.fc-prev-button.fc-button.fc-button-primary {
  display: inline-flex;
  order: 0;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
  padding: 17px 18px !important;
  gap: 10px;
  width: 43px;
  height: 48px;
  background: #fbfbfb !important;
  border: 1px solid #dadada !important;
  color: #939393 !important;
  border-radius: 8px !important;
  font-weight: bold !important;
  transition: all 0.2s linear !important;

  &:hover {
    background: #4dc1be !important;
    color: #fff !important;
  }
}

.fc-toolbar-chunk:nth-child(2) {
  @media (max-width: 690px) {
    font-size: 12px;
  }
}

.fc-toolbar-chunk:nth-child(3),
.fc-button-group {
  @media (max-width: 770px) {
    display: none;
  }
}

.fc-next-button.fc-button.fc-button-primary {
  @extend .fc-prev-button, .fc-button, .fc-button-primary;
  display: inline-flex;
  order: 2;
  flex-grow: 0;
  border-radius: 8px !important;
}

.fc-button:disabled {
  opacity: 1 !important;
}

.fc-daygrid-day-events {
  min-height: 18px !important;
  margin-bottom: 0 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  font-family: Inter;
  line-height: 16px !important;
}

.fc-timegrid-axis {
  border-top: 0 !important;
}

.fc-timegrid-axis-frame {
  display: none !important;
}

.fc-timegrid-divider {
  display: none !important;
}

.fc,
.fc-timegrid-slot-label-cushion {
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
}

// .fc-event-title {
//   display: none;
// }

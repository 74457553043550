.rst__rowWrapper {
  padding: 0;
}

.rst__rowContents {
  padding: 10px;
  height: 40px;
  border-radius: 0;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  color: #313745;
  letter-spacing: 0.2px;
  margin-top: 9px;
}

.rst__virtualScrollOverride {
  overflow: unset !important; //remove initial scroll
}

.rst__nodeContent {
  width: calc(100% - 60px);
}

.rst__rowContents {
  margin-right: auto;
  width: 100%;
}

.rst__rowLabel {
  white-space: initial;
  padding-right: 10px;
  height: 40px;
  overflow: auto;
  display: flex;
  align-items: center;
}

.rst__rowToolbar {
  justify-content: center;
  align-content: center;
  align-items: center;
  vertical-align: middle;
  margin-left: 0;
}

.rst__toolbarButton .ant-btn {
  height: 26px;
}

.rst__toolbarButton {
  margin-right: 5px;
}

.rst__toolbarButton:last-child {
  margin-right: 0;
}

.rst__toolbarButton .custom-button i {
  vertical-align: text-top;
}

.custom-button {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  user-select: none;
  cursor: pointer;
  line-height: 24px;
  padding: 1px 7px;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.75);
  align-self: center;
  text-align: center;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.custom-button + .custom-button {
  margin-left: 5px;
}

.custom-button:focus {
  outline: none;
}

.custom-button:hover,
.custom-button:active {
  color: #2a9dab;
  border-color: #2a9dab;
}

.custom-button i {
  font-size: 14px;
  width: 16px;
  text-align: center;
  vertical-align: baseline;
  display: inline-block;
}

.custom-button .glyphicon-plus:before {
  font-size: 0.875em;
}

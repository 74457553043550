.PhoneInputInput {
  border: none;
  outline: none;
  padding: 5px;
  // color: #35b7b9;
  font-weight: 700;
  text-align: start !important;
}

.card-container {
  width: 540px;
  padding: 8px 22px;
  margin: 60px auto;
  width: 100%;
  overflow: auto;

  @media (max-width: 1600px) {
    margin: 5px 60px 40px !important;
  }

  @media (max-width: 1200px) {
    margin: 5px auto 40px !important;
  }

  @media (max-width: 975px) {
    margin: 30px 10px 40px;
  }
}

.max1400 {
  max-width: 1400px;
}

.email-hover:hover {
}

.card-form {
  margin: 20px 0 20px 0;
}

.card-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;

  .dashboard {
    .card-wrapper {
      .ant-card {
        width: 100%;
        background: $wht;
        border-radius: 13px;
        box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.25);

        // @media (max-width: 768px) {
        //   border-radius: unset;
        //   box-shadow: none;
        // }

        .ant-card-head {
          border-radius: 13px 13px 0 0;
        }

        .ant-form-item {
          margin-bottom: 5px;
        }
      }
    }
  }
}

.action-button {
  background: #35b7b9;
  color: $wht;
  margin: 0;
  line-height: 16px;
  text-align: center;
  font-weight: 500;
  // border: 1px solid #c2dde0;
  box-sizing: border-box;
  border-radius: 8px;
  // min-width: 90px;
  width: 230px;
  height: 45px;
  padding: 15px 35px;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include transition(all 0.2s linear);

  &.change-password {
    width: 230px;
    margin-left: -15px;
    margin-top: 20px;
    text-align: center;
  }

  &.float-right,
  &.border-dark {
    // border: 1px solid #4dc1be;

    &.delete-button {
      border: 1px solid #ff7373;
      margin-left: 12px;
    }
  }

  img {
    margin-right: 5px;
    color: $wht !important;
  }

  span {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: $wht;
  }

  &:hover,
  &:focus {
    background: #c2dde0;
    // border: 1px solid #4dc1be;
    box-sizing: border-box;
  }

  &:focus {
    border: 1px solid #ffffff;
  }
}

.ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  margin-left: 15px;
}

.patient {
  .patient-profile-form-container {
    max-width: 1400px;
    display: flex;
    padding: 8px 22px;
    margin: 46px auto 42px auto;
    width: 100%;
    overflow: auto;

    &.create-report {
      max-width: 700px;
    }

    &.allowed-data-width {
      max-width: 700px;
    }
  }
}

.vertical-text {
  flex-direction: column;
}

.patient-nav {
  background-color: #c2dde0;
  display: flex;
  margin: 80px 105px;
  // margin: 50px 0 0 0;
  position: fixed;
  z-index: 100;
  width: 100%;
  padding-left: 40px;

  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 10px;

    a {
      text-decoration: none;

      .patient-nav-link {
        background-color: $wht;
        color: #4dc1be;
        font-weight: 700;
        width: 160px;
        height: 35px;
        border-radius: 10px 10px 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 7px 5px 5px 0;
        font-size: 14px;
        @include transition(all 0.2s linear);

        &.active,
        &:hover {
          cursor: pointer;
          background-color: #4dc1be;
          color: $wht;
          font-weight: 700;
        }
      }
    }
  }
}

.patient-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;

  .action-button {
    margin-right: 105px !important;

    &.delete-button {
      position: absolute;
      right: -81px !important;
      bottom: 12px !important;
    }
  }

  &.change-data {
    .action-button {
      margin-right: 0 !important;
    }
  }

  .ant-col {
    min-height: 32px;
  }
}

.patient-profile {
  .card-container {
    max-width: 1400px;

    &.mobile-top-space {
      max-width: 600px;
    }
  }
}

//patientHistory
.history-cards {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.documents-history-card {
  width: 100%;

  .ant-card {
    .ant-card-body {
      padding: 0 24px !important;
    }
  }
}

.documents-history {
  display: flex;
}

.document-dates {
  min-width: 300px;
  max-width: 300px;
  width: 100%;
  border-right: 1px solid #627b90;
  padding: 20px 10px;
  text-align: center;
}

.document-date {
  p {
    cursor: pointer;
    color: #627b90;
    border-radius: 13px;
    @include transition(all 0.2s linear);

    &:hover {
      background-color: #4dc1be;
      color: $wht;
    }
  }
}

.document-types {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  min-width: 120px;
  width: 100%;
}

.document {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-radius: 13px;
  padding: 10px;
  @include transition(all 0.2s linear);

  &:hover {
    background-color: #e4fafd;
  }

  img {
    height: 30px;
  }

  span {
    max-width: 100px;
    text-align: center;
    color: #627b90;
  }
}

.document-title {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  color: #627b90;
  margin: 20px 0;
}

.report-document-content {
  border: 1px solid #f5f5f5;
  margin-bottom: 15px;
}

.report-note {
  border-bottom: 1px solid #f5f5f5;
  padding: 5px;
  overflow: auto;

  p {
    color: #627b90;
  }

  span {
    color: #627b90;
    font-weight: 700;
  }

  .ant-form-item-label {
    min-width: 1px !important;
    padding-bottom: 0;

    > label {
      font-weight: 700;
      color: #627b90;

      &::after {
        margin: 0 !important;
      }
    }
  }

  textarea {
    &.ant-input {
      border: none !important;
      padding: 0;
      color: #627b90;
      font-weight: 400;
    }
  }
}

.doctor-signature {
  display: flex;
  flex-direction: column;

  span {
    &:last-of-type {
      margin-top: 15px;
    }
  }
}

.report-box-text {
  color: #627b90;
  width: 100%;
  font-size: 10px;
  padding: 5px;

  &.info {
    margin-bottom: 10px;
    padding: 5px 20px;
    border-bottom: 1px solid #f5f5f5;
  }
}

.profile-image-wrapper {
  &.report {
    .avatar {
      width: 50px;
      height: 50px;
      margin-right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      img {
        margin-top: 0;
      }
    }
  }
}

.report-col {
  .ant-form-item-label {
    min-width: 68px !important;
  }

  .ant-input {
    pointer-events: none;
  }

  textarea {
    &.ant-input {
      pointer-events: none;
    }
  }

  .ant-form-item-control-input-content {
    padding: 0;
    font-size: 12px;
  }

  .ant-form-item-label > label {
    font-size: 12px;
  }
}

.report-col {
  &.textarea {
    .ant-form-item-control-input-content {
      border: 1px solid #f5f5f5;
    }
  }
}

.report-ordination-info {
  margin: 10px 0;

  p {
    margin-bottom: 0;
    color: #627b90;
    font-size: 11px;
  }
}

.card-form {
  &.report {
    margin: 20px 0;

    .report-form-left-col {
      .ant-form-item-control-input-content {
        border-bottom: none;
      }
    }
  }

  &.patient-edit-form {
    .ant-form-item {
      margin-bottom: 15px;
    }

    .ant-col {
      min-height: 20px; //iphone 6s simulator require

      @media (max-width: 575px) {
        min-height: 52px; //iphone 6s simulator require
      }
    }
  }

  &.report-content-space {
    .ant-col-24 {
      &.ant-form-item-label {
        padding: 15px 0 0;
      }
    }
  }
}

.report-form-button-col {
  .ant-form-item-control-input-content {
    border-bottom: none;
  }
}

.doctor-modal {
  &.report {
    .ant-card-head-title {
      margin-left: 0;
    }
  }
}

//Reports
.report-title {
  margin: 20px 0 5px 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #627b90;
}

.report-text {
  font-weight: 400;
  font-size: 14px;
  color: #627b90;
  width: 33%;
}

.report-buttons {
  .action-button {
    margin-right: 15px !important;

    &:last-of-type {
      margin-right: 0 !important;
    }
  }
}

.action-button {
  margin-right: 10px;

  &.delete-button {
    &.report {
      right: -10px !important;
      margin-right: 10px !important;
    }
  }
}

.patient-card {
  &.calendar {
    margin-left: auto;

    .action-button {
      margin-right: 0px !important;

      &.float-right {
        margin-right: 0;
      }

      &.delete-button {
        margin-right: 0;
      }
    }
    @media (min-width: 530px) {
      .action-button {
        margin-left: 10px;
      }
    }
  }
}

.card-wrapper {
  &.therapy {
    .ant-card {
      border-radius: 0 0 13px 13px !important;
      box-shadow: 2px 4px 5px 2px rgba(0, 0, 0, 0.25) !important;
    }
  }
}

.new-patient-button-row {
  .action-button {
    margin-right: 0 !important;
  }
  .ant-row {
    justify-content: flex-end;
  }
}

.mobile-top-data {
  display: none;
}

//Report modal
.desktop-top-data-modal {
  display: flex;
  justify-content: space-between;
}

.report-note {
  &.modal {
    min-height: 130px;
  }
}

.report-top-data-modal-wrapper {
  width: 50%;
}

.report-top-data-modal {
  display: flex;
  align-items: center;
  padding: 2px 0;

  label {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #627b90;
    text-transform: uppercase;
    min-width: 68px;

    &.examination-type {
      min-width: 110px;
    }
  }

  input {
    border: none;
    border-bottom: 1px solid #e5e5e5;
    color: #627b90;
    border-radius: 0;
    padding: 5px;
    font-weight: 700;
    width: 100%;
    font-size: 12px !important;
    outline: none;
  }
}

@media print {
  .desktop-top-data-modal {
    flex-direction: row !important;
  }

  .report-top-data-modal-wrapper {
    width: 50% !important;
  }
}

//Prescription
.prescription-form {
  span,
  p {
    color: #627b90;
  }
}

.prescription-form-item-content {
  display: flex;
}

.prescription-input-description {
  text-align: center;
}

.empty-line {
  border: 1px solid #e5e5e5;
}

.prescription-form-empty-space {
  margin: 35px 0;
}

.prescription-description {
  font-weight: 700;
  font-size: 30px;
}

.ant-row {
  &.ant-form-item {
    &.label-diagnose {
      .ant-col {
        &.ant-form-item-label {
          label {
            color: #000000;
            opacity: 0.8;
            font-weight: 500;
            font-size: 16px;
          }
        }
      }
    }
  }
}

.prescription-form {
  .ant-input {
    padding: 5px;
  }
}

@media (max-width: 992px) {
  .history-cards {
    flex-direction: column;
  }

  .documents-history-card {
    margin-right: 0;
    width: 100%;
  }

  .list-card {
    margin-left: 0;
    margin-top: 20px;
    width: 100%;
  }

  .report-buttons {
    justify-content: flex-start;
  }

  .patient-sidebar {
    z-index: 6;
    flex: 0 0 0 !important;
    max-width: 0 !important;
    min-width: 0 !important;
    width: 0 !important;
  }
}

@media (max-width: 768px) {
  .action-button {
    &.delete-button {
      &.report {
        bottom: -20px !important;
      }
    }
  }

  .documents-history {
    display: flex;
  }

  .document-dates {
    padding: 10px 5px;
    min-width: 145px;
    max-width: 145px;
  }

  .document-types {
    padding: 5px;
  }

  .document {
    padding: 5px;
  }
}

@media (max-width: 750px) {
  .patient-nav {
    ul {
      a {
        .patient-nav-link {
          width: 120px;
          font-size: 14px;
        }
      }
    }
  }
}

.patient {
  .patient-profile-form-container {
    .profile-form-wrapper {
      margin-top: 50px;
    }
  }
}

@media (max-width: 600px) {
  .document-title {
    font-size: 14px;
  }
}

@media (max-width: 575px) {
  .patient {
    .patient-profile-form-container {
      .profile-form-wrapper {
        &.mobile-top-space-big {
          margin-top: 62px;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .desktop-top-data {
    display: none;
  }

  .mobile-top-data {
    display: block;
  }

  .card-form {
    &.report {
      .report-form-left-col {
        .ant-form-item-control-input-content {
          display: flex;
          margin-top: -20px;
        }
      }
    }

    .report-ordination-info {
      margin-left: 0;
    }
  }

  .mobile-report-inputs {
    .ant-row {
      overflow: hidden;
    }

    .ant-col {
      width: 100%;
    }

    .ant-form-item {
      flex-wrap: nowrap !important;

      .ant-form-item-label {
        flex: auto !important;
        min-width: 68px;
      }

      .ant-form-item-label > label {
        font-size: 12px;
      }
    }

    .ant-form-item-control-input-content {
      padding: 0;
      font-size: 12px;
    }

    .ant-input {
      pointer-events: none;
    }

    textarea {
      &.ant-input {
        pointer-events: none;
      }
    }
  }

  .prescription-form-empty-space {
    display: flex;
    justify-content: center;
  }

  .desktop-top-data-modal {
    flex-direction: column;
  }

  .report-top-data-modal-wrapper {
    width: 100%;
  }
}

@media (max-width: 420px) {
  .report-buttons {
    flex-direction: column;

    .action-button {
      margin-top: 10px !important;
    }
  }
}

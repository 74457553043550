.user-image {
  position: relative;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(204.56deg, #4dc1be 1.33%, #33d7da 92.1%);
  border-radius: 50%;
  cursor: pointer;

  img {
    height: 94px;
    width: 94px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }
}

.action-user-image {
  position: absolute;
  display: none;
  align-items: center;
  justify-content: center;
  width: 98px;
  height: 98px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  color: $wht;
  font-size: 20px;
  object-fit: cover;

  &.active {
    display: flex;
  }
}

.avatar-placeholder {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(204.56deg, #4dc1be 1.33%, #33d7da 92.1%);
  border-radius: 50%;
  cursor: pointer;
  margin: 0 5px 5px 0;
}

.avatar-image {
  background: white;
  border-radius: 50%;
  width: 89px;
  height: 89px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 50px;
    height: auto;
  }
}

@media (max-width: 768px) {
  .user-image,
  .avatar-placeholder {
    margin-top: 20px;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.profile-form-container {
  display: flex;
  max-width: 700px;
  padding: 8px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 102px);
  margin: auto;
}

.DayPicker-Day {
  border-radius: 0;
  border: 2px solid white;
}

.profile-form-wrapper,
.note-wrappe {
  width: 100%;

  .dashboard {
    .card-wrapper {
      .ant-card {
        width: 100%;
        border-radius: 13px;
        box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.25);

        // @media (max-width: 768px) {
        //   border-radius: unset;
        //   box-shadow: none;
        // }

        .ant-card-head {
          border-radius: 13px 13px 0 0;
        }

        .ant-card-body {
          position: relative;

          .show-specialization {
            position: absolute;
            top: 124px;
            right: 20px;
            z-index: 5;
          }
        }
      }

      .ant-form-item-label {
        min-width: 140px;

        @media (max-width: 575px) {
          min-width: 85px;
        }
      }

      .action-button {
        float: right;
        margin-right: 80px;

        &.delete-button {
          position: absolute;
          bottom: 11px;

          @include media('<=sm') {
            right: -85px;
          }

          span {
            color: #ff7373;
          }

          svg {
            margin-bottom: 1px;
            color: #ff7373;
          }

          &:hover,
          &:focus {
            background: #e9cfcd;
            border: 1px solid #ff7373;
            box-sizing: border-box;
          }

          &:focus {
            border: 1px solid #ffffff;
          }
        }
      }
    }
  }

  .ant-card .ant-form-item {
    margin-bottom: 0;
  }
}

.right-profile-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  opacity: 1 !important;
}

.doctor-profile-buttons-row {
  display: flex;
  justify-content: flex-start;

  @include media('<=sm') {
    flex-direction: column;
    align-items: baseline;
  }

  .action-button {
    margin-right: 0px !important;

    &.border-dark {
      &.sync {
        font-family: 'Roboto';
        font-size: 14px;
        text-decoration: none;
        margin-right: 10px;
        border: none !important;
        border-radius: 8px !important;
        // box-shadow: 0 1px 2px 1px #999;
        padding: 0 !important;

        & img {
          height: 18px;
          width: 18px;
          margin-left: 16px;
        }

        & span {
          display: flex;
          align-items: center;
        }

        a {
          text-decoration: none;
        }

        &:hover {
          background: #c2dde0 !important;
        }
      }

      &.change-password {
        min-width: 8rem;
        margin: 4px 4px 4px 3px;

        @media (max-width: 576px) {
          margin: 0 10px 8px 0;
        }
      }

      &.top-space {
        margin-top: 10px;
      }
    }
  }

  .delete-button {
    background: $wht;
    border: 1px solid #ff7373;
    box-sizing: border-box;
    border-radius: 8px;
    // min-width: 90px;
    width: 230px;
    height: 45px;
    font-weight: 700;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include transition(all 0.2s linear);

    &.top-space {
      margin: 8px 0 4px 0;

      &.deactivate {
        @media (max-width: 576px) {
          margin: 8px 0 8px 0;
        }
      }
    }

    span {
      color: #ff7373;
    }

    img {
      margin-right: 5px;
    }

    svg {
      margin-bottom: 1px;
      color: #ff7373;
    }

    &:hover,
    &:focus {
      background: #e9cfcd;
      border: 1px solid #ff7373;
      box-sizing: border-box;
    }

    &:focus {
      border: 1px solid #ffffff;
    }
  }
}

.ant-row {
  &.ant-form-item {
    &.working-hours {
      .ant-form-item-control-input {
        .ant-form-item-control-input-content {
          border-bottom: 1px solid #e5e5e5;
        }
      }
    }
  }
}

// @media (max-width: 1200px) {
//   .profile-container {
//     width: 100%;
//     display: flex;
//     justify-content: center;
//     margin-top: 50px;
//   }
// }

@media (max-width: 1024px) {
  .profile-form-container {
    margin-top: -8px;
    justify-content: unset;
    height: unset;
    margin-bottom: 42px;
  }
}

@media (max-width: 768px) {
  .right-profile-col {
    flex-direction: column-reverse;
  }
}

@font-face {
  font-family: 'Gotham Book';
  font-weight: normal;
  src: url('./../../../src/assets/fonts/Gotham\ Book\ Font.otf') format('otf');
}

@font-face {
  font-family: 'Gotham Medium';
  font-weight: normal;
  src: url('./../../../src/assets/fonts/gotham-medium.ttf') format('otf');
}

@font-face {
  font-family: 'Gotham Bold';
  font-weight: 800;
  src: url('./../../../src/assets/fonts/FontsFree-Net-Gotham-Bold.ttf') format('otf');
}
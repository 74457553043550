label {
  &.error {
    color: $error;
    font-size: 12px;
  }
}

.log {
  .card-wrapper {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    min-width: 500px;

    @include media('<md') {
      min-width: 320px;
    }

    .ant-card-head-title {
      font-size: 18px;
      font-weight: 700;
      color: #2c2638;
      font-weight: 100;
    }
  }
}

.dashboard {
  height: 100%;

  .card-wrapper {
    display: flex;
    height: 100%;

    &.dan {
      display: grid;
      max-width: 1400px;
    }

    .ant-card {
      width: 100%;

      @include media('<lg') {
        width: 100%;
      }

      .ant-card-body {
        padding: 28px 24px auto;
        overflow: auto;
        background-color: #fdfdfd;
        border-radius: 0 0 13px 13px;

        @media (max-width: 600px) {
          padding: 10px 10px;
        }
      }
    }
  }
}

.pacijent-view {
  .dashboard {
    .card-wrapper {
      .ant-card {
        .ant-card-body {
          .ant-row {
            flex-flow: wrap;
          }
        }
      }
    }
  }
}

.ant-form-item.upload-wrapper.right {
  .ant-form-item-control {
    flex-direction: row;
    justify-content: space-between;
  }

  .ant-upload-list-picture-card-container,
  .ant-upload.ant-upload-select-picture-card {
    margin: 0;
  }

  // .ant-form-item-control-input-content {
  //   border-bottom: none;
  // }
}

.ant-form-item-label {
  display: flex !important;
}

.ant-form-item-control-input-content {
  padding: 2px !important;
}

.ant-card {
  &.login-header {
    .ant-card-head {
      color: #2c2638;
      background-color: #ffffff;
    }
  }
}

.ant-card-head {
  color: #627b90;
  background-color: #ffffff;
  text-transform: capitalize;

  .ant-card-head-title {
    text-transform: initial;
  }

  @media (max-width: 600px) {
    padding: 0 10px;
  }
}

.ant-picker-large {
  padding: 5px 11px 6px;
}

.ant-picker {
  &.patient-form-calendar {
    width: 100%;
    border: none;
    border-bottom: 1px solid #e5e5e5;
  }
}

.red {
  color: red !important;
}

.ant-picker-input,
#basic_dateOfBirth,
.ant-picker-input > input {
  font-weight: bold;
  color: #627b90 !important;
}

.ant-picker-focused {
  box-shadow: 0 0 0 red !important;
}

.panel-primary > .panel-heading {
  color: #ffffff;
  background-color: #216c97;
  border-color: #216c97;
}

.panel-heading .panel-title span {
  background-color: transparent !important;
}

.log {
  .card-wrapper {
    .ant-card-head-title {
      text-align: center;
    }
  }
}

.ant-card {
  &.profile-title {
    .ant-card-head {
      background-color: #848587;
      color: #ffffff;
    }
  }
}

// .ant-form-item-label {
//   min-width: 140px;
// }

.card-wrapper {
  &.patient-card-form {
    .ant-form-item-label {
      min-width: 90px;
    }
  }
}

input,
select {
  font-size: 100% !important;
}

.ant-select-selection-overflow {
  height: 30px;
  overflow: auto;
}

.date-picker {
  cursor: pointer;
}

.ant-form.ant-form-horizontal.error-message {
  .ant-form-item-explain-error {
    color: $wht;
  }
}

@media (max-width: 400px) {
  .ant-card-head {
    font-size: 14px;
  }

  .ant-card-body {
    padding: 12px;
    font-size: 12px;
  }
}

#dateOfBirth {
  font-weight: 700 !important;
  color: #35b7b9 !important;
  font-size: 16px !important;
  text-align: start !important;
}
